import { gql } from 'graphql.macro';

export const getReservationDateRange = gql`
  query GetReservationDateRange($reservationId: Int) {
    reservation(reservationId: $reservationId) @client {
      from
      to
      season
      afternoonPickup
    }
  }
`;
export const seDateRangeMutation = gql`
  mutation SetDateRange(
    $reservationId: Int
    $from: String
    $to: String
    $season: Boolean!
    $afternoonPickup: Boolean!
  ) {
    setReservationDateRange(
      reservationId: $reservationId
      from: $from
      to: $to
      season: $season
      afternoonPickup: $afternoonPickup
    ) @client {
      from
      to
      season
      afternoonPickup
    }
  }
`;

export const phoneValidateMutation = gql`
  mutation PhoneValidate($number: String!) {
    phoneValidate(number: $number)
  }
`;

export const addToBasket = gql`
  mutation AddToBasket($input: AddSetToBasketInput!) {
    addSetToBasket(input: $input) @client {
      activeItem
    }
  }
`;

export const RemoveSet = gql`
  mutation RemoveSetFromBasket($reservationId: Int, $setId: Int!) {
    removeSetFromBasket(reservationId: $reservationId, setId: $setId) @client
  }
`;

export const clearBasket = gql`
  mutation ClearBasket {
    clearBasket @client
  }
`;

export const loadClosedDays = gql`
  query ClosedDays {
    closedDays {
        id
        from
        to
        recurring
        note
    }
    }
`;

export const getBasket = gql`
  query GetBasket($reservationId: Int) {
    basket(reservationId: $reservationId) @client {
      activeSetId
      sets {
        id
        type
        subType
        activeItem
        price
        bailPrice
        filters {
          sex
          height
          weight
          age
          fitness
          headSize
          snowboardPosition
        }
        items {
          id
          type
          useUniversal
          bailPrice
          catalogItemId
          catalogItem {
            id
            name
            producer
          }
        }
      }
    }
  }
`;

export const getActiveBasketSet = gql`
  query GetActiveBasketSet($basketSetId: Int) {
    basketSet(basketSetId: $basketSetId) @client {
      id
      type
      subType
      filters {
        sex
        height
        weight
        age
        fitness
        footSize
        skiSlope
        skiArc
        skills
        ccsStyle
        ccsPurpose
        childSex
        snowboardPosition
        headSize
      }
      items {
        id
        type
        catalogItemId
        useUniversal
      }
      activeItem
    }
  }
`;

export const getRecomendedItems = gql`
  query GetRecomendedItems(
    $input: RecomendedCatalogItemFilter!
    $from: DateTime!
    $to: DateTime!
    $set: Boolean!
    $season: Boolean!
    $morningPickUp: Boolean!
  ) {
    recomendedCatalogItems(input: $input) {
      items {
        id
        name
        size
        producer
        imagePath
        description
        priceForInterval(
          from: $from
          to: $to
          set: $set
          season: $season
          morningPickUp: $morningPickUp
        )
        priceForBail(season: $season, set: $set)
      }
    }
  }
`;

export const getAllSearchItems = gql`
  query GetAllSearchItems(
    $input: AllCatalogItemFilter!
    $from: DateTime!
    $to: DateTime!
    $set: Boolean!
    $season: Boolean!
    $morningPickUp: Boolean!
  ) {
    allSearchItems(input: $input) {
      items {
        id
        name
        size
        producer
        imagePath
        description
        priceForInterval(
          from: $from
          to: $to
          set: $set
          season: $season
          morningPickUp: $morningPickUp
        )
        priceForBail(season: $season, set: $set)
      }
    }
  }
`;

export const getSearchString = gql`
  query GetSearchString {
    searchString @client
  }
`;

export const getBasketSummary = gql`
  query GetBasketSummary($reservationId: Int) {
    basket(reservationId: $reservationId) @client {
      sets {
        id
        type
        subType
        price
        bailPrice
        filters {
          footSize
          weight
          height
          age
          fitness
          headSize
        }
        items {
          id
          type
          catalogItemId
          price
          useUniversal
          bailPrice
          catalogItem {
            id
            imagePath
            name
            producer
            size
          }
        }
      }
    }
  }
`;

export const removeCatalogItemFromBasketSet = gql`
  mutation RemoveCatalogItemFromSet($basketSetId: Int!, $catalogItemId: Int!) {
    removeCatalogItemFromBasketSet(basketSetId: $basketSetId, catalogItemId: $catalogItemId) @client
  }
`;

export const addCatalogItemToSet = gql`
  mutation AddCatalogItemToSet(
    $basketSetId: Int!
    $basketItemId: Int!
    $catalogItemId: Int!
    $price: Int!
    $bailPrice: Int!
  ) {
    addCatalogItemToSet(
      basketSetId: $basketSetId
      basketItemId: $basketItemId
      catalogItemId: $catalogItemId
      price: $price
      bailPrice: $bailPrice
    ) @client {
      redirectToSummary
    }
  }
`;

export const setCustomerInfo = gql`
  mutation SetCustomerInfo(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $customerId: Int
  ) {
    setCustomerInfo(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      customerId: $customerId
    ) @client
  }
`;

export const setDeliveryInfo = gql`
  mutation SetDeliveryInfo(
    $zipCode: String
    $city: String
    $streetNumber: String
    $streetName: String
    $customerId: Int
  ) {
    setDeliveryInfo(
      zipCode: $zipCode
      city: $city
      streetNumber: $streetNumber
      streetName: $streetName
      customerId: $customerId
    ) @client
  }
`;

export const createReservation = gql`
  mutation CreateReservation($order: OrderInput!, $sets: [SetInput!]) {
    createReservation(order: $order, sets: $sets) {
      paymentUrl
    }
  }
`;

export const removeItemFromSet = gql`
  mutation RemoveItemFromSet($itemId: Int!, $setId: Int!) {
    removeItemFromBasketSet(itemId: $itemId, setId: $setId) @client
  }
`;

export const setActiveBasketSetFilters = gql`
  mutation SetBaksetSetFilters($input: SetBasketSetFilters!, $basketSetId: Int) {
    setBaksetSetFilters(input: $input, basketSetId: $basketSetId) @client
  }
`;

export const activateSetItem = gql`
  mutation ActivateSetItem($itemId: Int!, $setId: Int!) {
    activateSetItem(itemId: $itemId, setId: $setId) @client
  }
`;

export const getSelectedReservationId = gql`
  query GetSelectedReservationId {
    selectedReservationId @client
  }
`;

export const getCanContinueToReservation = gql`
  query GetCanContinueToReservation {
    canContinueToReservation @client
  }
`;

export const getReservationHeaderLabel = gql`
  query GetReservationHeaderLabel {
    reservationHeaderLabel @client
  }
`;

export const getFinishReservation = gql`
  query GetFinishReservation {
    finishReservation @client
  }
`;

export const getBasketModalOpen = gql`
  query GetBasketModalOpen {
    basketModalOpen @client
  }
`;

export const reservationPriceSum = gql`
  query ReservationPriceSum {
    priceSum @client {
      bail
      rental
      delivery
    }
  }
`;

export const searchAllProducts = gql`
  query SearchAllProducts {
    searchAllProducts @client
  }
`;

export const getCustomerInfo = gql`
  query GetCustomerInfo {
    getCustomerInfo @client {
      id
      firstName
      lastName
      email
      phoneNumber
      city
      zipCode
      streetName
      streetNumber
    }
  }
`;

export const getReservationSubmitted = gql`
  query GetReservationSubmitted {
    reservationSubmitted @client
  }
`;

export const getClientFormValid = gql`
  query GetClientFormValid {
    clientFormValid @client
    allBasketFiltersValid @client
    deliveryFormValid @client
  }
`;

export const getAddToBasketModal = gql`
  query GetAddToBasketModal {
    addedToBasketModal @client
  }
`;

export const setBasketModalOpen = gql`
  mutation SetBasketModalOpen($open: Boolean!) {
    setBasketModalOpen(open: $open) @client
  }
`;

export const getSelectedPickupTime = gql`
  query GetSelectedPickupTime {
    pickupTimeId @client
  }
`;

export const getPickupTimes = gql`
  query GetPickupTimes {
    getPickupTimes {
      id
      from
      to
    }
  }
`;

export const getReservationsForPickupTime = gql`
  query GetReservationsForPickupTime($pickupTimeId: Int!, $date: DateTime!) {
    getReservationsForPickupTime(pickupTimeId: $pickupTimeId, date: $date)
  }
`;

export const order = gql`
  query Order($id: Int!) {
    order(id: $id) {
      id
      paymentUrl
    }
  }
`;

export const configQuery = gql`
  query ConfigQuery {
    configQuery {
      seasonEnabled
      twoSeasonsEnabled
      addressDeliveryDisabled
    }
  }
`;

export const orderByGopayId = gql`
  query OrderByPaymentQuery($gopayId: ID!) {
    orderByPayment(gopayId: $gopayId) {
      id
      price
      state
      payments {
        id
        amount
        type
      }
      sets {
        id
        catalogItems {
          id
          name
          price
          producer
          categoryName
          variant
        }
      }
      paymentUrl
    }
  }
`

export const orderDetailQuery = gql`
  query OrderDetailQuery($id: Int!) {
    order(id: $id) {
      id
      price
      payments {
        id
        amount
        type
      }
      sets {
        id
        catalogItems {
          id
          name
          price
          producer
          categoryName
          variant
        }
      }
    }
  }
`;
