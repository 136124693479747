import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { navigate, RouteComponentProps } from '@reach/router';
import { orderByGopayId } from 'graphql/queries';
import {
  OrderByPaymentQuery,
  OrderByPaymentQueryVariables,
  OrderState,
  PaymentType,
} from 'graphql/types';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import colors from 'lib/colors';
import { isNotNull } from 'lib/helpers';
import { groupBy } from 'ramda';
import React from 'react';
import checked from '../assets/checked.svg';
import close from '../assets/close.svg';
import info from '../assets/infoIcon.svg';
import Box from './Box';
import Flex from './Flex';
import PageWrapper from './PageWrapper';
import Text from './Text';

interface IProps {
  orderId?: string;
}

const GopayOrderStatus: React.FC<RouteComponentProps<IProps>> = props => {
  const { trackEvent } = useGoogleAnalytics();
  const gopayId = new URLSearchParams(props.location?.search).get('id');
  const { data: orderData } = useQuery<OrderByPaymentQuery, OrderByPaymentQueryVariables>(
    orderByGopayId,
    {
      variables: { gopayId: gopayId! },
      skip: !gopayId,
      fetchPolicy: 'network-only',
      onCompleted: data => {
        if (data?.orderByPayment?.state === OrderState.RESERVATION_PAID) {
          const order = data.orderByPayment;
          let shipping = 0;
          const shippingPa = order.payments?.filter(pa => pa?.type === PaymentType.DELIVERY);
          if (shippingPa && shippingPa[0]) {
            shipping = shippingPa[0].amount;
          }
          const price = order.price || 0;
          const tax = Math.ceil((price - price / 1.21) * 100) / 100;
          const catalogItems = order.sets?.flatMap(set => set?.catalogItems) || [];
          const caItemsById = groupBy(ca => ca.id.toString(), catalogItems.filter(isNotNull));
          trackEvent('purchase', {
            currency: 'CZK',
            transaction_id: order.id,
            value: price,
            tax,
            shipping,
            items: Object.entries(caItemsById).map(([, items]) => ({
              item_id: items[0].id,
              item_name: items[0].name,
              price: items[0].price,
              item_brand: items[0].producer,
              item_category: items[0].categoryName,
              item_variant: items[0].variant,
              quantity: items.length,
            })),
          });
        }
        setTimeout(() => navigate('/'), 5000);
      },
      onError: () => setTimeout(() => navigate('/'), 5000),
    },
  );

  const renderStatus = () => {
    if (!orderData) {
      return <ProcessingMessage />;
    }
    if (orderData.orderByPayment?.state === OrderState.RESERVATION_PAID) {
      return <SuccessMessage />;
    }
    if (orderData.orderByPayment?.state === OrderState.RESERVATION_CANCELED) {
      return <ErrorMessage />;
    }
    return <ProcessingMessage paymentUrl={orderData.orderByPayment?.paymentUrl} />;
  };
  return (
    <PageWrapper hideFooter simpleHeader>
      <Flex mt={6} justifyContent="center" flexDirection="column">
        {renderStatus()}
      </Flex>
    </PageWrapper>
  );
};

export default GopayOrderStatus;

const ProcessingMessage = ({ paymentUrl }: { paymentUrl?: string | null }) => {
  return (
    <Flex mt={4} alignItems="center" flexDirection="column">
      <CentralBox css={{ backgroundColor: colors.primary }}>
        <Image src={info} alt="info" />
        <br />
        <Text fontSize={[19, 24]} color={colors.white}>
          Platby GoPay se stále processuje.
        </Text>
      </CentralBox>
      {paymentUrl ? (
        <Box mt={4}>
          <Text fontSize={[15]}>
            Na aktulální stav se můžete podívat <a href={paymentUrl}>zde</a>.
          </Text>
        </Box>
      ) : null}
    </Flex>
  );
};

const ErrorMessage = () => {
  return (
    <Flex mt={4} alignItems="center" flexDirection="column">
      <CentralBox css={{ backgroundColor: colors.lightRed }}>
        <Image src={close} alt="close" />
        <br />
        <Text fontSize={[19, 24]} color={colors.white}>
          Behem platby GoPay nastala chyba
        </Text>
      </CentralBox>
    </Flex>
  );
};

const SuccessMessage = () => {
  return (
    <Flex mt={4} alignItems="center" flexDirection="column">
      <CentralBox>
        <Image src={checked} alt="checked" />
        <br />
        <Text fontSize={[19, 24]} color={colors.white}>
          Platba GoPay proběhla v pořádku,{' '}
        </Text>
        <Text fontSize={[19, 24]} color={colors.white}>
          děkujeme za platbu
        </Text>
      </CentralBox>
      <Box mt={4}>
        <Text fontSize={[15]}>Potvrzení Vám bylo zasláno na Váš e-mail</Text>
      </Box>
    </Flex>
  );
};

const CentralBox = styled.div({
  display: 'flex',
  backgroundColor: colors.green,
  padding: '60px',
  alignItems: 'center',
  flexDirection: 'column',
});

const Image = styled.img({
  width: 'auto',
  height: 64,
  objectFit: 'contain',
});
