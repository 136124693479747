import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Flex } from 'components';
import SelectList from 'components/SelectList';
import { BasketSetType, CatalogItemType } from 'graphql/types';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { GruppedCatalogItem as GruppedCatalogItemType } from 'lib/types';
import { stylePrice } from 'lib/utils';
import React, { useState } from 'react';
import notFound from '../../assets/imageNotFound.jpg';
import colors from '../../lib/colors';
import Box from '../Box';
import Button from '../Button';
import Text from '../Text';

interface IProps {
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  isFirst: boolean;
  basketItemType: CatalogItemType;
  setType: BasketSetType;
  activeId: number | null;
  isDisabled: boolean;
  onSelect: (id: number, price: number, bailPrice: number) => void;
  selectedItemId: number | null | undefined;
}

const GruppedCatalogItem: React.FC<IProps & GruppedCatalogItemType> = ({
  activeId,
  ids,
  ...props
}) => {
  const active = activeId ? ids.indexOf(activeId) > -1 : false;
  const [selected, setSelected] = useState(active ? activeId || ids[0] : ids[0]);
  const [price, setPrice] = useState(props.priceForInterval);
  const handleBoxClick = () => {
    if (!props.isDisabled) {
      props.setActive(active ? null : props.id);
    }
  };

  const { trackEvent } = useGoogleAnalytics();

  const handleSelectSize = (id: string) => {
    const idInt = parseInt(id, 10);
    const index = ids.indexOf(idInt);
    if (index > -1) {
      props.setActive(props.id);
      setSelected(idInt);
      setPrice(props.prices[index]);
    }
  };

  const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    trackEvent('add_to_cart', {
      currency: 'CZK',
      value: props.priceForInterval,
      items: [
        {
          item_id: props.id.toString(),
          item_name: props.producer + ' ' + props.name,
          item_variant: props.basketItemType,
          item_category: props.setType,
          item_brand: props.producer,
          price: props.priceForInterval,
        },
      ],
    });

    props.onSelect(selected, price, props.priceForBail);
  };

  const selectItems = ids.map((id, idx) => ({
    id: id.toString(),
    name: props.sizes[idx],
    price: props.prices[idx],
  }));
  let selectInputLabel = '';
  switch (props.basketItemType) {
    case CatalogItemType.MAIN_ITEM:
      switch (props.setType) {
        case BasketSetType.SKIALP_FREERIDE:
        case BasketSetType.SKI:
          selectInputLabel = 'Velikost lyží';
          break;
        case BasketSetType.CCS:
          selectInputLabel = 'Velikost běžek';
          break;
        case BasketSetType.SNOWBOARD:
          selectInputLabel = 'Velikost snowboardu';
          break;
      }
      break;
    case CatalogItemType.POLES:
      selectInputLabel = 'Velikost hůlek';
      break;
    case CatalogItemType.BOOTS:
      selectInputLabel = 'Velikost bot';
      break;
    case CatalogItemType.HEAD:
      selectInputLabel = 'Velikost helmy';
      break;
  }

  return (
    <Flex
      onClick={handleBoxClick}
      role="button"
      padding={[3]}
      width={300}
      flexDirection="column"
      css={css({
        border: `1px solid ${active ? colors.primary : colors.grey}`,
        opacity: props.isDisabled ? 0.25 : 1,
      })}
    >
      {ids.find(id => id === props.selectedItemId) ? (
        <Box height={0} position="relative" left={24} bottom={32} width={'80%'}>
          <Box
            padding={'8px 16px'}
            backgroundColor={colors.primary}
            color={colors.white}
            css={css({ textAlign: 'center' })}
          >
            <Text fontSize={[16]}>Vybráno</Text>
          </Box>
        </Box>
      ) : (
        props.isFirst &&
        !props.isDisabled && (
          <Box height={0} position="relative" left={24} bottom={32} width={'80%'}>
            <Box
              padding={'8px 16px'}
              backgroundColor={colors.red}
              color={colors.white}
              css={css({ textAlign: 'center' })}
            >
              <Text fontSize={[16]}>Doporučeno pro Vás</Text>
            </Box>
          </Box>
        )
      )}
      <Box mb={[3]}>
        <Image src={props.imagePath || notFound} alt="image" />
      </Box>
      <Box css={css({ textTransform: 'uppercase' })} mb={[2]} height={36} overflow={'hidden'}>
        <Text fontSize={[16]}>{`${props.producer} ${props.name}`}</Text>
      </Box>
      <Box overflowY={'auto'} height={[56, 38]} mb={[3]} title={props.description || ''}>
        <Text fontSize={[14]} fontWeight={['lighter']}>
          {props.description}
        </Text>
      </Box>
      <Box mb={[3]}>
        {price !== 0 ? (
          <>
            <Text fontSize={[14]}>CENA: </Text>
            <Text fontSize={16} fontWeight="bolder">
              {stylePrice(price)}
            </Text>
          </>
        ) : (
          <Text fontSize={[14]} fontWeight="bolder">
            V CENĚ SETU
          </Text>
        )}
      </Box>
      <Box mb={[3]}>
        <SelectList
          items={selectItems}
          selected={selected.toString()}
          onSelect={handleSelectSize}
          label={selectInputLabel}
          isActive={active}
        />
      </Box>
      <Button
        label="Vybrat"
        isBlue
        disabled={props.isDisabled || !active}
        onClick={handleSelectItem}
        css={css({
          width: '100%',
          marginTop: 'auto',
          '&:disabled': {
            pointerEvents: 'none',
          },
        })}
      />
    </Flex>
  );
};

export default GruppedCatalogItem;

const Image = styled.img({
  width: '100%',
  height: 250,
  objectFit: 'contain',
});
