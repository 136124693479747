import styled from '@emotion/styled';
import colors from 'lib/colors';
import React from 'react';
import Flex from './Flex';
import Text from './Text';

interface Props {
  items: { id: string; name: string }[];
  onSelect: (id: string) => void;
  label?: string;
  selected: string;
  wizardComponent?: boolean;
  isActive: boolean;
}

const SelectList = ({ items, onSelect, label, selected, wizardComponent, isActive }: Props) => {
  const selectedItem = items.find(item => item.id === selected);
  return (
    <Container wizardComponent={wizardComponent}>
      <Flex pl={wizardComponent ? 0 : 3} mb={2}>
        <Text fontSize="13px" lineHeight={wizardComponent ? '20px' : 'auto'}>
          {label}
        </Text>
      </Flex>
      <SizesContainer>
        {items.map(item => (
          <SelectItem
            key={item.id}
            item={item}
            isSelected={item.id === selectedItem?.id}
            onClick={() => onSelect(item.id)}
            isActive={isActive}
          />
        ))}
      </SizesContainer>
    </Container>
  );
};

interface ItemProps {
  onClick: (id: string) => void;
  item: { name: string; id: string };
  isSelected: boolean;
  isActive: boolean;
}

const SelectItem = ({ onClick, item, isSelected, isActive }: ItemProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onClick(item.id);
  };
  return (
    <ItemContainer shouldHighlight={isSelected && isActive}>
      <Button onClick={handleClick}>
        <span>{item.name}</span>
      </Button>
    </ItemContainer>
  );
};

export default SelectList;

const ItemContainer = styled.li<{ shouldHighlight: boolean }>`
  background: ${props => (props.shouldHighlight ? colors.primary : colors.white)};
  color: ${props => (props.shouldHighlight ? colors.white : colors.black)};
  font-weight: ${props => (props.shouldHighlight ? 'bold' : 'normal')};
  :hover {
    background-color: ${props => (props.shouldHighlight ? colors.primaryDark : colors.grey)};
  }
`;

const Button = styled.button`
  border: none;
  padding: 8px 16px;
  color: inherit;
  background: transparent;
  font-weight: inherit;
  font-size: 13px;
  width: 100%;
  outline: none;
  text-align: left;
`;

const Container = styled.div<{ wizardComponent?: boolean }>`
  position: relative;
  width: 100%;
  ${props => (props.wizardComponent ? 'margin: 8px;' : 'margin-top: 16px;')}
`;

const SizesContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
