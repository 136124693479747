import { getActiveBasketSet } from 'graphql/queries';
import { AddSetToBasketItemInput, CatalogItemType, GetBasket_basket } from 'graphql/types';
import {
  activateSetItem,
  countReservationPrice,
  getBasketSet,
  getBasketSetKey,
  getCacheBasket,
  getReservationHeaderLabel,
  getSelectedReservationId,
  updatePricesForSetUniversalItems,
  validateBasketsFilters,
  validateCanContinueToReservation,
  validateFinishReservation,
} from 'lib/utils';
import {
  basketItems,
  BasketSetFragment,
  dataCatalogItemFragment,
  ReservationBasketFragment,
} from './fragments';
import {
  AddSetToBasketVariables,
  BasketItemsFragmentType,
  CacheBasket,
  CacheBasketSet,
  ResolverContext,
} from './types';

const itemsOrder = [
  CatalogItemType.MAIN_ITEM,
  CatalogItemType.BOOTS,
  CatalogItemType.HEAD,
  CatalogItemType.POLES,
  CatalogItemType.BAG,
  CatalogItemType.BACKPACK,
  CatalogItemType.BAG_WITH_ABS,
  CatalogItemType.FINDER,
];

function compareBasketItems(a: AddSetToBasketItemInput, b: AddSetToBasketItemInput) {
  if (a.useUniversal) return 1;
  if (b.useUniversal) return -1;
  return itemsOrder.indexOf(a.id) - itemsOrder.indexOf(b.id);
}

export default {
  Query: {
    basket: (
      root: any,
      { reservationId }: { reservationId: number },
      { cache, getCacheKey }: ResolverContext,
      info: any,
    ) => {
      const cacheBasket = getCacheBasket(cache, getCacheKey, reservationId);
      if (cacheBasket) {
        const response: GetBasket_basket = {
          activeSetId: cacheBasket.basket.activeSetId,
          sets: [],
          __typename: cacheBasket.basket.__typename,
        };
        cacheBasket.basket.setIds.forEach(setId => {
          const basketKey = getCacheKey({ __typename: 'BasketSet', id: setId });
          const basketSet: CacheBasketSet | null = cache.readFragment({
            fragment: BasketSetFragment,
            id: basketKey,
          });
          if (basketSet) {
            const items = basketSet.items.map(item => {
              if (item.catalogItemId) {
                const catalogItemKey = getCacheKey({
                  __typename: 'CatalogItem',
                  id: item.catalogItemId,
                });
                item.catalogItem = cache.readFragment({
                  fragment: dataCatalogItemFragment,
                  id: catalogItemKey,
                });
              } else {
                item.catalogItem = null;
              }
              return item;
            });
            basketSet.items = items;
            response.sets.push(basketSet);
          }
        });
        return response;
      }
    },
  },
  Mutation: {
    addSetToBasket: async (
      root: any,
      { input }: { input: AddSetToBasketVariables },
      { cache, getCacheKey, client }: ResolverContext,
    ) => {
      const selectedReservationId = getSelectedReservationId(cache);
      if (selectedReservationId !== null) {
        const canFinishReservationBeforeAdd = validateFinishReservation(cache, getCacheKey);
        const reservationId = input.reservationId || selectedReservationId;
        const id = getCacheKey({ __typename: 'Reservation', id: reservationId });
        const cacheData: { basket: CacheBasket } | null = cache.readFragment({
          fragment: ReservationBasketFragment,
          id,
        });
        if (cacheData) {
          const basketSetsCount = cacheData.basket.setIds.length;
          const newbasketId =
            reservationId * 200 + ((cacheData.basket.setIds[basketSetsCount - 1] || 0) + 1);
          const name = `BasketSet_${newbasketId}`;
          const sortedItems = input.items.sort(compareBasketItems);
          const activeItemIndex = sortedItems.findIndex(item => !item.useUniversal);
          const newBasketSet: { [x: string]: CacheBasketSet } = {
            [name]: {
              id: newbasketId,
              type: input.type,
              subType: input.subType,
              price: 0,
              bailPrice: 0,
              filters: {
                sex: input.sex,
                height: null,
                weight: null,
                age: null,
                fitness: null,
                footSize: null,
                skiSlope: null,
                skiArc: null,
                skills: null,
                ccsStyle: null,
                ccsPurpose: null,
                childSex: null,
                snowboardPosition: null,
                headSize: null,
                __typename: 'WizardFilters',
              },
              filtersValid: false,
              items: sortedItems.map((item, idx) => ({
                id: newbasketId * 200 + idx,
                type: item.id,
                useUniversal: !!item.useUniversal,
                catalogItemId: null,
                catalogItem: null,
                price: 0,
                bailPrice: 0,
                __typename: 'BasketCatalogItem',
              })),
              activeItem: ~activeItemIndex ? newbasketId * 200 + activeItemIndex : null,
              __typename: 'BasketSet',
            },
          };
          const onlyUniversalItems = newBasketSet[name].items.every(item => item.useUniversal);
          let activeSetId = null;
          if (basketSetsCount) {
            activeSetId = cacheData.basket.activeSetId;
            if (!onlyUniversalItems && !cacheData.basket.activeSetId) {
              activeSetId = basketSetsCount + 1;
            }
          } else if (!onlyUniversalItems) {
            activeSetId = 1;
          }

          const data: { basket: CacheBasket } = {
            ...cacheData,
            basket: {
              ...cacheData.basket,
              activeSetId,
              setIds: [...cacheData.basket.setIds, newbasketId],
            },
          };
          cache.writeData({ id, data });
          cache.writeData({ data: newBasketSet });
          const reservationHeaderLabel = getReservationHeaderLabel(
            input.type,
            input.subType,
            input.sex,
            input.items[0].id,
          );
          cache.writeData({ data: { addedToBasketModal: reservationHeaderLabel } });
          if ((basketSetsCount === 0 || canFinishReservationBeforeAdd) && activeSetId != null) {
            cache.writeData({ data: { reservationHeaderLabel } });
            try {
              // when this read will not throw an Error, activeSetItem can safely do its job
              cache.readQuery({ query: getActiveBasketSet });
              activateSetItem(activeSetId, cache, getCacheKey);
            } catch (e) {}
          }
          validateCanContinueToReservation(cache, getCacheKey);
          validateFinishReservation(cache, getCacheKey);
          const result = await updatePricesForSetUniversalItems(newBasketSet[name], {
            cache,
            getCacheKey,
            client,
          });
          if (!~activeItemIndex && !activeSetId && result.length) {
            activateSetItem(1, cache, getCacheKey, result[0]);
          }
          validateBasketsFilters({ cache, getCacheKey, client });
        }
      }
    },
    removeSetFromBasket: (
      root: any,
      { reservationId, setId }: { reservationId: number; setId: number },
      { cache, getCacheKey, client }: ResolverContext,
    ) => {
      const selectedReservationId = getSelectedReservationId(cache);
      if (selectedReservationId !== null) {
        const id = reservationId || selectedReservationId;
        const reservationKey = getCacheKey({ __typename: 'Reservation', id });
        const cacheData: { basket: CacheBasket } | null = cache.readFragment({
          fragment: ReservationBasketFragment,
          id: reservationKey,
        });
        if (cacheData) {
          const setIds = cacheData.basket.setIds;
          const removeIndex = setIds.indexOf(setId);
          if (removeIndex > -1) {
            setIds.splice(removeIndex, 1);
          }
          let activeSetId: number | null = null;
          setIds.forEach(setId => {
            if (!activeSetId) {
              const basketKey = getBasketSetKey(cache, getCacheKey, setId);
              const basket = cache.readFragment<BasketItemsFragmentType>({
                fragment: basketItems,
                id: basketKey,
              });
              if (basket && !basket.items.every(item => item.useUniversal)) {
                activeSetId = setId;
              }
            }
          });
          cache.writeFragment({
            fragment: ReservationBasketFragment,
            id: reservationKey,
            data: {
              ...cacheData,
              basket: { ...cacheData.basket, setIds, activeSetId },
            },
          });
          validateCanContinueToReservation(cache, getCacheKey);
          validateFinishReservation(cache, getCacheKey);
          countReservationPrice(cache, getCacheKey);
          validateBasketsFilters({ cache, getCacheKey, client });
        }
      }
    },
    clearBasket: (root: any, params: any, { cache, getCacheKey }: ResolverContext) => {
      const selectedReservationId = getSelectedReservationId(cache);
      if (selectedReservationId !== null) {
        const reservationKey = getCacheKey({
          __typename: 'Reservation',
          id: selectedReservationId,
        });
        const cacheData: { basket: CacheBasket } | null = cache.readFragment({
          fragment: ReservationBasketFragment,
          id: reservationKey,
        });
        if (cacheData) {
          cacheData.basket.setIds.forEach(setId => {
            const basketSet = getBasketSet(cache, getCacheKey, setId);
            const basketKey = getBasketSetKey(cache, getCacheKey, setId);
            if (basketSet && basketKey) {
              const newBasketSet = { ...basketSet };
              newBasketSet.items = basketSet.items.map(item => ({
                ...item,
                catalogItem: null,
                catalogItemId: null,
                price: 0,
              }));
              newBasketSet.price = 0;
              newBasketSet.bailPrice = 0;
              newBasketSet.activeItem = setId * 200;
              cache.writeFragment({
                fragment: BasketSetFragment,
                id: basketKey,
                data: newBasketSet,
              });
              countReservationPrice(cache, getCacheKey);
              validateFinishReservation(cache, getCacheKey);
            }
          });
          activateSetItem(cacheData.basket.setIds[0], cache, getCacheKey);
        }
      }
      return true;
    },
    setBasketModalOpen: (root: any, { open }: { open: boolean }, { cache }: ResolverContext) => {
      cache.writeData({
        data: {
          basketModalOpen: open,
        },
      });
      return open;
    },
  },
};
