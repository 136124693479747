import styled from '@emotion/styled';
import colors from 'lib/colors';
import React from 'react';
import close from '../assets/close.svg';
import Box from './Box';
import Flex from './Flex';
import PageWrapper from './PageWrapper';
import Text from './Text';
import { RouteComponentProps } from '@reach/router';
import { navigate } from '@reach/router';
import queryString from 'query-string';
import QueryComponent from './QueryComponent';
import { order } from '../graphql/queries';
import { Order, OrderVariables } from '../graphql/types';

const OrderError: React.FC<RouteComponentProps> = props => {
  const handleOnClick = (url: string) => () => {
    navigate(url);
  };

  return (
    <PageWrapper hideFooter simpleHeader>
      <Flex mt={6} justifyContent="center" flexDirection="column">
        <Flex mt={4} alignItems="center" flexDirection="column">
          <CentralBox>
            <Image src={close} alt="close" />
            <br />
            <Text fontSize={[19, 24]} color={colors.white}>
              Platba neproběhla,{' '}
            </Text>
            <Text fontSize={[19, 24]} color={colors.white}>
              prosím proveďte platbu znova
            </Text>
          </CentralBox>
          <Box mt={4}>
            <QueryComponent<Order, OrderVariables>
              query={order}
              options={{
                variables: {
                  id: Number.parseInt(
                    queryString.parse(props?.location?.search || '').orderId as string,
                    10,
                  ),
                },
                fetchPolicy: 'no-cache',
              }}
            >
              {({ data }) => (
                <Button
                  onClick={handleOnClick(data?.order?.paymentUrl || '')}
                  css={{ cursor: 'pointer' }}
                >
                  <Text fontSize={[12, 16, 18]}>ZKUSIT ZNOVU</Text>
                </Button>
              )}
            </QueryComponent>
          </Box>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};

export default OrderError;

const CentralBox = styled.div({
  display: 'flex',
  backgroundColor: colors.lightRed,
  padding: '60px',
  alignItems: 'center',
  flexDirection: 'column',
});

const Image = styled.img({
  width: 'auto',
  height: 64,
  objectFit: 'contain',
});

const Button = styled.div({
  color: colors.primary,
  fontWeight: 'bolder',
  textDecoration: 'none',
  padding: '12px 48px',
  border: `1px solid ${colors.primary}`,
});
