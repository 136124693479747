import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  FlexboxProps,
  flexbox,
} from 'styled-system';

export type BoxProps = SpaceProps | ColorProps | LayoutProps | PositionProps | FlexboxProps;

const Box = styled('div', { shouldForwardProp })<BoxProps>(
  compose(space, color, layout, position, flexbox),
);

Box.displayName = 'Box';

export default Box;
