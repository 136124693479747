import { useMutation } from '@apollo/react-hooks';
import { css } from '@emotion/core';
import { Box, Flex, Text } from 'components';
import Modal from 'components/Modal';
import QueryComponent from 'components/QueryComponent';
import { getBasket, getBasketModalOpen, setBasketModalOpen } from 'graphql/queries';
import {
  GetBasket,
  GetBasketModalOpen,
  SetBasketModalOpen,
  SetBasketModalOpenVariables,
} from 'graphql/types';
import React from 'react';
import { useUID } from 'react-uid';
import BasketModalFooter from './BasketModalFooter';
import BasketSet from './BasketSet';

interface IProps {
  reservationBasket?: boolean;
  homeBasket?: boolean;
}

const BasketModal: React.FC<IProps> = ({ homeBasket = false, reservationBasket = false }) => {
  const [setModalOpen] = useMutation<SetBasketModalOpen, SetBasketModalOpenVariables>(
    setBasketModalOpen,
  );

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const handleCloseRequest = () => {
    setModalOpen({ variables: { open: false } });
  };

  const modalId = useUID();

  return (
    <QueryComponent<GetBasketModalOpen> query={getBasketModalOpen}>
      {({ data }) => (
        <Modal
          isOpen={data.basketModalOpen}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={handleCloseRequest}
          aria={{
            labelledby: modalId,
          }}
          type="BasketModal"
        >
          <Flex
            width={['100vw']}
            height={['100vh']}
            paddingX={[4, 5]}
            paddingY={[3, 4]}
            flexDirection="column"
          >
            <Box flex="1 0 auto">
              <Box css={css({ textAlign: 'center' })}>
                <Text
                  fontSize={16}
                  fontWeight={'bolder'}
                  css={css`
                    text-transform: uppercase;
                  `}
                >
                  Košík
                </Text>
              </Box>
              <Box
                mt={[4, 5]}
                ml="12px"
                overflowY={'scroll'}
                maxHeight={`calc(100vh - ${isSafari ? '350' : '182'}px)`}
              >
                <QueryComponent<GetBasket>
                  query={getBasket}
                  options={{ fetchPolicy: 'cache-and-network' }}
                >
                  {({ data }) => (
                    <Box
                      css={css`
                        margin-right: 12px;
                      `}
                    >
                      {data.basket.sets.map((set, idx) => (
                        <BasketSet
                          reservationBasketSet={reservationBasket}
                          key={set.id}
                          active={set.id === data.basket.activeSetId}
                          set={set}
                          canBeRemoved={data.basket.sets.length > 1}
                          onRemoveItem={
                            data.basket.sets.length === 1 ? handleCloseRequest : () => null
                          }
                        />
                      ))}
                    </Box>
                  )}
                </QueryComponent>
              </Box>
            </Box>
            <Flex
              flexShrink={0}
              justifyContent={['space-between', 'space-evenly']}
              css={css(isSafari ? { marginBottom: 60 } : {})}
              data-tip
              data-for="continue"
            >
              <BasketModalFooter
                handleCloseRequest={handleCloseRequest}
                reservationBasket={reservationBasket}
                homeBasket={homeBasket}
              />
            </Flex>
          </Flex>
        </Modal>
      )}
    </QueryComponent>
  );
};

export default BasketModal;
