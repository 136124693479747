import styled from '@emotion/styled';
import colors from 'lib/colors';

export const SpinnigLoader = styled.div({
  width: 70,
  height: 70,
  borderRadius: '50%',
  border: `5px solid ${colors.primaryLight}`,
  borderLeftColor: colors.primary,
  backgroundColor: 'transparent',
  animation: 'rotate-s-loader 1s linear infinite',
  margin: 'margin: 6rem auto',
  '@keyframes rotate-s-loader': {
    from: {
      transform: 'rotate(0)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});
