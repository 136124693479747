// useGoogleAnalytics.ts
import { useEffect } from 'react';
import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';

interface UseGoogleAnalyticsProps {
  trackEvent: <T>(eventName: string, params: T) => void;
  handleAcceptCookie: () => void;
  handleDeclineCookie: () => void;
}

const useGoogleAnalytics = (): UseGoogleAnalyticsProps => {
  const initializeAnalytics = () => {
    const key = process.env.GOOGLE_ANALYTICS;
    if (key !== undefined) {
      ReactGA.initialize(key);
    }
  };

  useEffect(() => {
    const hasAcceptedCookies = getCookieConsentValue();

    if (hasAcceptedCookies) {
      initializeAnalytics();
    }

    return () => {};
  }, []);

  const trackEvent = <T>(eventName: string, params: T): void => {
    const hasAcceptedCookies = getCookieConsentValue();

    if (hasAcceptedCookies && !JSON.parse(hasAcceptedCookies)) return;
    if (window.gtag) {
      window.gtag('event', eventName, params);
    }
  };

  const handleAcceptCookie = () => {
    initializeAnalytics();
  };

  const removeCookiesStartingWith = (prefix: string): void => {
    Object.keys(Cookies.get()).forEach(cookieName => {
      if (cookieName.indexOf(prefix) === 0) {
        Cookies.remove(cookieName);
      }
    });
  };

  removeCookiesStartingWith('_ga');

  const handleDeclineCookie = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
    removeCookiesStartingWith('_ga');
  };

  return {
    trackEvent,
    handleAcceptCookie,
    handleDeclineCookie,
  };
};

export default useGoogleAnalytics;
