import styled from '@emotion/styled';
import { flexbox, FlexboxProps } from 'styled-system';
import Box, { BoxProps } from './Box';

export type FlexProps = BoxProps | FlexboxProps;

/**
 * Fully customizable flex container
 */
const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${flexbox}
`;

Flex.displayName = 'Flex';

export default Flex;
