import { useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { navigate, RouteComponentProps } from '@reach/router';
import { orderDetailQuery } from 'graphql/queries';
import { OrderDetailQuery, OrderDetailQueryVariables, PaymentType } from 'graphql/types';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import colors from 'lib/colors';
import { isNotNull } from 'lib/helpers';
import { groupBy } from 'ramda';
import React from 'react';
import checked from '../assets/checked.svg';
import Box from './Box';
import Flex from './Flex';
import PageWrapper from './PageWrapper';
import Text from './Text';

interface IProps {
  orderId?: string;
}

const OrderPlaced: React.FC<RouteComponentProps<IProps>> = props => {
  const { trackEvent } = useGoogleAnalytics();
  useQuery<OrderDetailQuery, OrderDetailQueryVariables>(orderDetailQuery, {
    variables: { id: Number(props.orderId) },
    skip: !props?.orderId,
    onCompleted: data => {
      const order = data.order;
      if (null === order) return;
      let shipping = 0;
      const shippingPa = order.payments?.filter(pa => pa?.type === PaymentType.DELIVERY);
      if (shippingPa && shippingPa[0]) {
        shipping = shippingPa[0].amount;
      }
      const price = order.price || 0;
      const tax = Math.ceil((price - price / 1.21) * 100) / 100;
      const catalogItems = order.sets?.flatMap(set => set?.catalogItems) || [];
      const caItemsById = groupBy(ca => ca.id.toString(), catalogItems.filter(isNotNull));

      trackEvent('purchase', {
        currency: 'CZK',
        transaction_id: order.id,
        value: price,
        tax,
        shipping,
        items: Object.entries(caItemsById).map(([, items]) => ({
          item_id: items[0].id,
          item_name: items[0].name,
          price: items[0].price,
          item_brand: items[0].producer,
          item_category: items[0].categoryName,
          item_variant: items[0].variant,
          quantity: items.length,
        })),
      });
      setTimeout(() => navigate('/'), 5000);
    },
    onError: () => setTimeout(() => navigate('/'), 5000),
  });

  return (
    <PageWrapper hideFooter simpleHeader>
      <Flex mt={6} justifyContent="center" flexDirection="column">
        <Flex mt={4} alignItems="center" flexDirection="column">
          <CentralBox>
            <Image src={checked} alt="checked" />
            <br />
            <Text fontSize={[19, 24]} color={colors.white}>
              Platba proběhla v pořádku,{' '}
            </Text>
            <Text fontSize={[19, 24]} color={colors.white}>
              děkujeme za platbu
            </Text>
          </CentralBox>
          <Box mt={4}>
            <Text fontSize={[15]}>Potvrzení Vám bylo zasláno na Váš e-mail</Text>
          </Box>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};

export default OrderPlaced;

const CentralBox = styled.div({
  display: 'flex',
  backgroundColor: colors.green,
  padding: '60px',
  alignItems: 'center',
  flexDirection: 'column',
});

const Image = styled.img({
  width: 'auto',
  height: 64,
  objectFit: 'contain',
});
