import { gql } from 'graphql.macro';

export const ReservationBasketFragment = gql`
  fragment basket on Reservation {
    basket {
      setIds
      activeSetId
    }
  }
`;

export const ReservationDurationFragment = gql`
  fragment reservationDuration on Reservation {
    from
    to
    season
    afternoonPickup
  }
`;

export const BasketSetFragment = gql`
  fragment basketSet on BasketSet {
    id
    filters {
      sex
      height
      weight
      age
      footSize
      fitness
      skiSlope
      skiArc
      skills
      ccsStyle
      ccsPurpose
      childSex
      snowboardPosition
      headSize
    }
    items {
      id
      type
      catalogItemId
      bailPrice
      price
      useUniversal
    }
    filtersValid
    type
    subType
    activeItem
    price
    bailPrice
  }
`;

export const canContinueToReservation = gql`
  fragment canContinue on Reservation {
    from
    to
    basket {
      setIds
    }
  }
`;

export const basketSetPriceFragment = gql`
  fragment price on BasketSet {
    price
    bailPrice
  }
`;

export const customerReservationFragment = gql`
  fragment customer on Reservation {
    customerId
  }
`;

export const dataCatalogItemFragment = gql`
  fragment data on CatalogItem {
    id
    imagePath
    name
    producer
    size
  }
`;

export const customerInfo = gql`
  fragment info on Customer {
    email
    firstName
    id
    lastName
    phoneNumber
    zipCode
    city
    streetName
    streetNumber
  }
`;

export const universalCatalogItem = gql`
  fragment universal on BasketCatalogItem {
    useUniversal
  }
`;

export const updateUniversalCatalogItem = gql`
  fragment updateUniversal on BasketCatalogItem {
    catalogItemId
    catalogItem
    price
    bailPrice
  }
`;

export const basketItems = gql`
  fragment basketItems on BasketSet {
    items {
      useUniversal
    }
  }
`;

export const filtersValid = gql`
  fragment filtersValid on BasketSet {
    filtersValid
  }
`;
