import styled from '@emotion/styled';
import { Link, RouteComponentProps } from '@reach/router';
import colors from 'lib/colors';
import React from 'react';
import { mq } from 'theme';
import notFound from '../assets/notFound.png';
import Box from './Box';
import Flex from './Flex';
import PageWrapper from './PageWrapper';
import Text from './Text';

const NotFound: React.FC<RouteComponentProps> = props => {
  return (
    <PageWrapper hideFooter simpleHeader>
      <Flex mt={6} justifyContent="center" flexDirection="column">
        <Image src={notFound} alt="notFound" />
        <Flex mt={4} alignItems="center" flexDirection="column">
          <Text fontSize={[18, 36, 50]}>Stránka nebyla nalezena</Text>
          <Box mt={4}>
            <Link to="/" css={{ textDecoration: 'none' }}>
              <LinkButton>
                <Text fontSize={[12, 16, 18]}>PŘEJÍT NA HLAVNÍ STRÁNKU</Text>
              </LinkButton>
            </Link>
          </Box>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};

export default NotFound;

const LinkButton = styled.div({
  color: colors.primary,
  fontWeight: 'bolder',
  textDecoration: 'none',
  padding: '12px 48px',
  border: `1px solid ${colors.primary}`,
});

const Image = styled.img({
  width: 'auto',
  height: 64,
  objectFit: 'contain',
  [mq[0]]: {
    height: 92,
  },
  [mq[1]]: {
    height: 128,
  },
});
