import { getReservationDateRange } from 'graphql/queries';
import { GetReservationDateRange } from 'graphql/types';
import {
  getReservatioFragment,
  getSelectedReservationId,
  validateCanContinueToReservation,
  updatePricesForReservationUniversalItems,
} from 'lib/utils';
import { ReservationVariables, ResolverContext, SetReservationDateRangeVariables } from './types';

export default {
  Query: {
    reservation: (
      root: any,
      { reservationId }: ReservationVariables,
      { cache, getCacheKey }: ResolverContext,
      info: any,
    ) => {
      // in Current verison of apollo when  selectedReservationId @client @export(as: "reservationId") id used
      // with useQuery it resolves in calling local recolover multiple times
      const selectedReservationId = getSelectedReservationId(cache);
      if (selectedReservationId !== null) {
        const id = getCacheKey({
          __typename: 'Reservation',
          id: reservationId || selectedReservationId,
        });
        return cache.readFragment({ fragment: getReservatioFragment(info), id });
      }
    },
  },
  Mutation: {
    setReservationDateRange: (
      root: any,
      { reservationId, from, to, season, afternoonPickup }: SetReservationDateRangeVariables,
      { cache, getCacheKey, client }: ResolverContext,
      info: any,
    ) => {
      const selectedReservationId = getSelectedReservationId(cache);
      if (selectedReservationId !== null) {
        const fragment = getReservatioFragment(info);
        const id = getCacheKey({
          __typename: 'Reservation',
          id: reservationId || selectedReservationId,
        });
        const cacheDate: any = cache.readFragment({ fragment, id });
        const data = { ...cacheDate, season, from: from || null, to: to || null, afternoonPickup };
        cache.writeData({ id, data });
        const reservationQuery: GetReservationDateRange | null = cache.readQuery({
          query: getReservationDateRange,
        });
        if (reservationQuery) {
          cache.writeQuery({
            query: getReservationDateRange,
            data: {
              ...reservationQuery,
              reservation: {
                ...reservationQuery.reservation,
                from,
                to,
                season,
                afternoonPickup,
              },
            },
          });
        }
        validateCanContinueToReservation(cache, getCacheKey);
        if (data.from && data.to) {
          updatePricesForReservationUniversalItems(id, { cache, getCacheKey, client });
        }
      }
    },
  },
};
