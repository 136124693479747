const colors = {
  black: 'black',
  white: 'white',
  borderGrey: '#DADBDB',
  primary: '#62B8F2',
  primaryLight: '#C3DDEE',
  primaryDark: '#4DB2E8',
  grey: '#F4F4F4',
  border: '#D4D5D6',
  greyDark: '#707070',
  green: '#64B15F',
  backButtonGray: '#A7A7A7',
  red: '#A8302D',
  lightRed: '#F44336',
};

export default colors;
