import { css } from '@emotion/core';
import styled from '@emotion/styled';
import colors from 'lib/colors';
import React from 'react';
import LogoImage from '../assets/czskiLogo.svg';
import { mq, theme } from '../theme';
import BasketButton from './BasketButton';
import Box from './Box';
import Flex from './Flex';
import Text from './Text';
import { Link } from '@reach/router';

interface IProps {
  simpleHeader?: boolean;
}

const Header: React.FC<IProps> = ({ simpleHeader: simpleModal = false }) => {
  return (
    <Flex
      backgroundColor={colors.black}
      alignItems="end"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Flex>
        <Box mt={3} mr="24px" mb="10px">
          <Link to={'/'}>
            <Logo src={LogoImage} theme={theme}/>
          </Link>
        </Box>
        <Flex display={['none', 'flex']} alignItems="center">
          <Box display={['none', 'block']}>
            <Text
              color={colors.white}
              fontWeight="lighter"
              fontSize={[18, 18, 24]}
              css={css({ textTransform: 'uppercase' })}
            >
              Půjčovna
              <br /> lyžařského vybavení
            </Text>
          </Box>
        </Flex>
      </Flex>
      {!simpleModal && <BasketButton />}
    </Flex>
  );
};

export default Header;

const Logo = styled.img(_ => ({
  width: 48,
  height: 48,
  [mq[0]]: {
    width: 60,
    height: 60,
  },
  [mq[2]]: {
    width: 71,
    height: 71,
  },
}));
