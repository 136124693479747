import * as Sentry from '@sentry/browser';
import { captureMessage } from '@sentry/core';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import resolvers from './resolvers';
import { isServerErrorKnown } from './ServerErrors';
import DebounceLink from 'apollo-link-debounce';
import { navigate } from '@reach/router';
import fetch from 'cross-fetch';

const DEFAULT_DEBOUNCE_TIMEOUT = 1000;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      if(error.extensions?.code === 'DISABLED'){
         navigate('/maintenance', { state: {
           errorMessage: error.message.split(":")[1]
         }});
         return;
      }
      // we know about these errors
      if (!isServerErrorKnown(error.name)) {
        // this is in fact NOT an error object despite ts type so use captureMessage instead
        captureMessage(`GraphQL error: ${error.message}`, Sentry.Severity.Error);
      }
      // tslint:disable-next-line no-console
      console.error('[GraphQL error]:', error);
    });
  }
  if (networkError) {
    // tslint:disable-next-line no-console
    Sentry.captureException(networkError);
    console.error('[Network error]:', networkError);
  }
});

const GRAPHQL_URI = '/api/graphql';

const httpLink = new HttpLink({
  uri: GRAPHQL_URI,
  fetch
});

const loggingLink = new ApolloLink((operation, forward) => {
  Sentry.addBreadcrumb({
    message: `Starting request for ${operation.operationName}`,
    category: 'graphql',
    data: { variables: operation.variables },
  });
  if (forward == null) {
    return null;
  }

  return forward(operation).map(data => {
    Sentry.addBreadcrumb({
      message: `Ending request for ${operation.operationName}`,
      category: 'graphql',
      data: {
        variables: operation.variables,
        result: data,
      },
    });
    return data;
  });
});

const appTypeLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      ApplicationType: 'online-reservation'
    }
  });

  return forward(operation);
});

const cache = new InMemoryCache();

export default async function createClient() {
  await persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>,
  });
  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      errorLink,
      loggingLink,
      appTypeLink,
      httpLink,
    ]),
    // @ts-ignore
    resolvers,
  });
}
