import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import './global.css';
import './initSentry';
import * as serviceWorker from './serviceWorker';
require('dotenv/config');

smoothscroll.polyfill();
const root = document.getElementById('root');

Modal.setAppElement(root!);

ReactDOM.render(<App />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
