import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from '@reach/router';
import { Box, Button, Flex, Text } from 'components';
import QueryComponent from 'components/QueryComponent';
import {
  getCanContinueToReservation,
  getFinishReservation,
  reservationPriceSum,
} from 'graphql/queries';
import {
  GetCanContinueToReservation,
  GetFinishReservation,
  ReservationPriceSum,
} from 'graphql/types';
import colors from 'lib/colors';
import { stylePrice } from 'lib/utils';
import { transparentize } from 'polished';
import React from 'react';
import { mq } from 'theme';
import ReactTooltip from 'react-tooltip';

interface IProps {
  reservationBasket?: boolean;
  homeBasket?: boolean;
  handleCloseRequest: () => void;
}

const BasketModalFooter: React.FC<IProps> = ({
  homeBasket = false,
  reservationBasket = false,
  handleCloseRequest,
}) => {
  const closeButton = (
    <Button
      css={css({
        color: colors.primary,
        border: `1px solid ${colors.primary}`,
        padding: 8,
        fontSize: 12,
        [mq[0]]: {
          fontSize: 16,
          padding: 12,
        },
      })}
      label="Vybrat další"
      onClick={handleCloseRequest}
    />
  );
  return (
    <>
      {homeBasket && (
        <>
          {closeButton}
          <QueryComponent<GetCanContinueToReservation> query={getCanContinueToReservation}>
            {({ data }) => (
              <>
                <NextButtonLink
                  to="/reservation"
                  disabled={!data.canContinueToReservation}
                  onClick={handleCloseRequest}
                >
                  Pokračovat
                </NextButtonLink>
                {!data.canContinueToReservation && (
                  <ReactTooltip id="continue" type="error" effect="solid">
                    <span>
                      Musíte zvolit datum zápůjčky <br /> a tlačítkem „vybrat“ přidat <br /> zvolené
                      vybavení do košíku.
                    </span>
                  </ReactTooltip>
                )}
              </>
            )}
          </QueryComponent>
        </>
      )}
      {reservationBasket && (
        <>
          <Box width="100%">
            <QueryComponent<ReservationPriceSum> query={reservationPriceSum}>
              {({ data }) => (
                <Flex justifyContent="space-between" width="100%">
                  <Flex flexDirection="column" mt={2}>
                  <Flex flexDirection="column">
                    <Text
                      css={css({
                        fontSize: 12,
                        color: colors.black,
                        textTransform: 'uppercase',
                      })}
                    >
                      Průběžná cena
                    </Text>
                    <Text
                      css={css({
                        fontSize: 16,
                        fontWeight: 'bolder',
                        color: colors.primary,
                      })}
                    >
                      {stylePrice(data.priceSum.rental + data.priceSum.delivery)}
                    </Text>
                  </Flex>
                    <Flex flexDirection="column" mt={2}>
                      <Text
                        css={css({
                          fontSize: 12,
                          color: colors.black,
                          textTransform: 'uppercase',
                        })}
                      >
                        Průběžná kauce
                      </Text>
                      <Text
                        css={css({
                          fontSize: 16,
                          fontWeight: 'bolder',
                          color: colors.primary,
                        })}
                      >
                        {stylePrice(data.priceSum.bail)}
                      </Text>
                    </Flex>
                  </Flex>
                  {closeButton}
                </Flex>
              )}
            </QueryComponent>
            <Flex justifyContent="space-between" width="100%" mt={3}>
              <BackButtonLink onClick={handleCloseRequest} to="/">
                Zpět
              </BackButtonLink>
              <QueryComponent<GetFinishReservation> query={getFinishReservation}>
                {({ data }) => (
                  <>
                    <NextButtonLink
                      onClick={handleCloseRequest}
                      to="/reservation-summary"
                      disabled={!data.finishReservation}
                    >
                      pokračovat
                    </NextButtonLink>
                    {!data.finishReservation && (
                      <ReactTooltip id="continue" type="error" effect="solid">
                        <span>Musíte si vybrat všechny položky do všech setů</span>
                      </ReactTooltip>
                    )}
                  </>
                )}
              </QueryComponent>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
};

export default BasketModalFooter;

const NextButtonLink = styled(Link)<{ disabled: boolean }>(props => ({
  padding: 8,
  textTransform: 'uppercase',
  color: props.disabled ? transparentize(0.7, colors.green) : colors.green,
  border: `1px solid ${props.disabled ? transparentize(0.7, colors.green) : colors.green}`,
  pointerEvents: props.disabled ? 'none' : 'auto',
  textDecoration: 'none',
  backgroundColor: colors.white,
  fontSize: 12,
  fontWeight: 'bold',
  [mq[0]]: {
    fontSize: 16,
    padding: 12,
  },
}));

const BackButtonLink = styled(Link)({
  backgroundColor: 'transparent',
  fontSize: 12,
  fontWeight: 'bold',
  color: colors.backButtonGray,
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
});
