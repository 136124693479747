export enum ButtonsGroupFilterType {
  SKILLS = 'skills',
  SKISLOPE = 'skiSlope',
  SKIARC = 'skiArc',
  CCSSTYLE = 'ccsStyle',
  CCSPURPOSE = 'ccsPurpose',
  CHILDSEX = 'childSex',
  SNOWBOARD_POSITION = 'snowboardPosition',
}

export enum SliderFilterType {
  HEIGHT = 'height',
  WEIGHT = 'weight',
  HEADSIZE = 'headSize',
}

export enum InputFilterType {
  FOOTSIZE = 'footSize',
  AGE = 'age',
}

export enum SelectFilterType {
  FITNESS = 'fitness',
}

export enum WizardFilterDescriptionType {
  SKILLS = 'skills',
  SKIARC = 'skiArc',
  CCSPURPOSE = 'ccsPurpose',
  FOOTSIZE = 'footSize',
}

export enum FormInputType {
  DELIVERY = 'DELIVERY',
  DELIVERY_WEEKEND = 'DELIVERY_WEEKEND',
  DELIVERY_AFTERNOON = 'DELIVERY_AFTERNOON',
  DELIVERY_FORENOON = 'DELIVERY_FORENOON',
  DELIVERY_FRIDAY = 'DELIVERY_FRIDAY',
}

export enum ServerErrorContext {
  GET_RECOMENDED_ITEMS = 'getRecomendedItems',
  CREATE_RESERVATION = 'CREATE_RESERVATION',
}

export enum Skills {
  BEGINNER = 'BEGINNER',
  RECREATIONAL = 'RECREATIONAL',
  SPORT = 'SPORT',
  FREERIDE = 'FREERIDE',
}
