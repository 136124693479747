import { css } from '@emotion/core';
import React from 'react';
import { mq } from 'theme';
import Box from './Box';

const ContentContainer: React.FC = props => (
  <Box
    marginX={['12px', 4, 5]}
    maxWidth="1620px"
    css={css({
      [mq[3]]: { margin: '0px 96px' },
      '@media (min-width: 1720px)': { margin: '0px auto' },
    })}
  >
    {props.children}
  </Box>
);
export default ContentContainer;
