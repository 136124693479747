import styled from '@emotion/styled';
import { transparentize } from 'polished';
import React from 'react';
import { layout, LayoutProps } from 'styled-system';
import colors from '../lib/colors';

interface Props extends LayoutProps {
  label: string | React.ReactNode;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: React.ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isBlue?: boolean;
}

const Button = ({ label, type, onClick, isBlue = false, ...props }: Props) => {
  return (
    <StyledButton {...props} onClick={onClick} isBlue={isBlue}>
      {typeof label === 'string' ? label.toLocaleUpperCase() : label}
    </StyledButton>
  );
};

export default Button;

interface StyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isBlue: boolean;
}

const StyledButton = styled('button')<StyledButtonProps>`
  border: none;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  padding: 8px 0;
  font-size: 15px;
  font-weight: bold;
  color: ${({ isBlue }) => (isBlue ? colors.white : colors.black)};
  background-color: ${({ isBlue }) => (isBlue ? colors.primary : 'transparent')};
  padding: 8px 0;
  font-size: 14px;
  font-weight: bold;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: auto;
    color: ${({ isBlue }) => (isBlue ? colors.white : transparentize(0.4, colors.black))};
    background-color: ${({ isBlue }) => (isBlue ? colors.border : 'transparent')};
  }

  ${layout}
`;
