import css from '@emotion/css';
import styled from '@emotion/styled';
import colors from 'lib/colors';
import React from 'react';
import BasketModal from './Basket/BasketModal';
import Box from './Box';
import ContentContainer from './ContentContainer';
import Flex from './Flex';
import Footer from './Footer';
import Header from './Header';
import ScrollToTop from './ScrollToTop';

interface IProps {
  homeFooter?: boolean;
  reservationFooter?: boolean;
  summaryFooter?: boolean;
  hideFooter?: boolean;
  headerComponent?: JSX.Element | null;
  componentUnderHeader?: JSX.Element | null;
  componentBeforeFooter?: JSX.Element | null;
  componentUnderHeaderBackgroundColor?: string;
  simpleHeader?: boolean;
  reservationBasketModal?: boolean;
}

const PageWrapper: React.FC<IProps> = ({
  hideFooter = false,
  reservationBasketModal = false,
  ...props
}) => {
  return (
    <Flex height="100%" width="100%" flexDirection="column">
      <Content>
        <Box backgroundColor="black" py={3}>
          <ContentContainer>
            <Flex
              flexDirection={['column', 'column', 'column', 'row']}
              alignItems={['inherit', 'inherit', 'inherit', 'center']}
            >
              <Header simpleHeader={props.simpleHeader} />
              {props.headerComponent}
            </Flex>
          </ContentContainer>
        </Box>
        {props.componentUnderHeader && (
          <Box css={css({ backgroundColor: props.componentUnderHeaderBackgroundColor })}>
            <ContentContainer>{props.componentUnderHeader}</ContentContainer>
          </Box>
        )}
        <ContentContainer>{props.children}</ContentContainer>
      </Content>
      {props.componentBeforeFooter && props.componentBeforeFooter}
      {/*
        // @ts-ignore */}
      <FooterWrapper
        position={[props.homeFooter ? 'fixed' : 'initial', 'fixed']}
        mt={[4]}
        css={css({ bottom: 0, width: '100%', backgroundColor: 'white' })}
      >
        {!hideFooter && (
          <Box
            css={css`
              color: ${colors.white};
              border-top: 1px solid ${colors.borderGrey};
            `}
          >
            <ContentContainer>
              <Footer
                home={props.homeFooter}
                reservation={props.reservationFooter}
                summary={props.summaryFooter}
              />
            </ContentContainer>
          </Box>
        )}
      </FooterWrapper>
      <ScrollToTop />
      {!props.simpleHeader && (
        <BasketModal reservationBasket={reservationBasketModal} homeBasket={props.homeFooter} />
      )}
    </Flex>
  );
};

export default PageWrapper;

const Content = styled.div({
  flex: '1 0 auto',
  paddingBottom: 200,
});

const FooterWrapper = styled(Box)({
  flexShrink: 0,
});
