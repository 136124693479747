import { mergeDeepRight, reduce } from 'ramda';
import basket from './basket';
import basketSet from './basketSet';
import customer from './customer';
import reservation from './reservation';

// @ts-ignore
const resolvers = reduce(mergeDeepRight, {}, [reservation, basket, basketSet, customer]);

export default resolvers;
