import { css } from '@emotion/core';
import colors from 'lib/colors';
import React, { useLayoutEffect, useState } from 'react';
import ArrowUp from '../assets/arrowUp.svg';
import Button from './Button';

const ScrollToTop: React.FC = () => {
  const [renderButton, setRenderButton] = useState(false);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  });

  const handleScrollEvent = () => {
    const height = window.outerHeight;
    if (window.scrollY > 1.2 * height) {
      setRenderButton(true);
    } else {
      setRenderButton(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {renderButton && (
        <Button
          onClick={handleScrollTop}
          label={<img src={ArrowUp} alt="" />}
          css={css({
            position: 'fixed',
            bottom: 96,
            right: 16,
            borderRadius: '50%',
            padding: 0,
            width: 48,
            height: 48,
            backgroundColor: colors.white,
            boxShadow: '0px 3px 6px #00000041',
          })}
        />
      )}
    </>
  );
};

export default ScrollToTop;
