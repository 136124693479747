import { ServerErrorContext } from 'lib/enums';

export function getServerErrorTransaltion(
  errorName: string,
  errorContext: ServerErrorContext,
): string | null {
  switch (errorContext) {
    case ServerErrorContext.GET_RECOMENDED_ITEMS:
      switch (errorName) {
        case 'NothingFoundError':
          return 'Nenašli jsme žádný produkt, který bychom vám mohli doporučit';
        case 'NotEnoughtInfo':
          return 'Nedostatek informací pro vyhledávání';
        case 'NoSizeFound':
          return 'Pro zadaná data nemáme tabulku pro přepočet na velikost';
        case 'EmptyHeighOrWeighttForMainItem':
          return 'Je potřeba nastavit výšku a váhu';
        case 'EmptyFootSizeForBoots':
          return 'Je třeba nastavit délku chodidla';
        case 'EmptyHeadSizeForHEAD':
          return 'Je třeba nastavit obvod hlavy';
        case 'EmptyHeightForMainItem':
          return 'Je třeba nastavit výšku';
        case 'EmptySnowBoardPositionForSnowBoard':
          return 'Je třeba zvolit postavení nohou';
        default:
          return 'Neznámý error';
      }
    case ServerErrorContext.CREATE_RESERVATION:
      switch (errorName) {
        case 'ItemNotAvailable':
          return 'Položka již není dostupná';
        case 'ItemIsNotCompatibleWithSet':
          return 'Zvolená položka není kompatibylní se setem';
        case 'canNotAddSetItemToExistingSet':
          return 'V jednom setu nemohou bý dvě hlavní položky';
        default:
          return null;
      }
    default:
      return null;
  }
}

export function isServerErrorKnown(errorName: string) {
  const errorTranslation = Object.values(ServerErrorContext).reduce(
    (translation, errorContext) =>
      getServerErrorTransaltion(errorName, errorContext) || translation,
    '',
  );
  return errorTranslation.length > 0;
}
