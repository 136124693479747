import { ApolloCache } from 'apollo-cache';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { gql } from 'graphql.macro';
import {
  BasketSetFragment,
  basketSetPriceFragment,
  canContinueToReservation,
  customerInfo,
  customerReservationFragment,
  filtersValid,
  ReservationBasketFragment,
  ReservationDurationFragment,
  universalCatalogItem,
  updateUniversalCatalogItem,
} from 'graphql/resolvers/fragments';
import {
  CacheBasket,
  CacheBasketSet,
  canContinueToReservationFragment,
  ResolverContext,
  CacheReservationDuration,
  ReservationBasketFragmentType,
  FiltersValidFragmentType,
} from 'graphql/resolvers/types';
import {
  BasketSubType,
  GetActiveBasketSet,
  GetBasket,
  GetRecomendedItems_recomendedCatalogItems_items as CatalogItem,
  GetRecomendedItems_recomendedCatalogItems_items,
  GetSelectedReservationId,
  GetRecomendedItems,
  GetRecomendedItemsVariables,
  BasketSetType,
} from 'graphql/types';
import { GruppedCatalogItem as GruppedCatalogItemType } from 'lib/types';
import { groupWith, omit } from 'ramda';
import {
  getActiveBasketSet,
  getBasket,
  getSelectedReservationId as getSelectedReservationIdQuery,
  getRecomendedItems,
} from '../graphql/queries';
import { getTranslation } from './translations';

export function stylePrice(price: number) {
  return price.toLocaleString('cs-CS', {
    style: 'currency',
    minimumFractionDigits: 0,
    currency: 'CZK',
  });
}

export function getReservatioFragment(info: any) {
  const fragment: any = gql`
    fragment dateRange on Reservation {
      id
    }
  `;
  fragment.definitions[0].selectionSet.selections = info.field.selectionSet.selections;
  return fragment;
}

export function getSelectedReservationId(cache: ApolloCache<NormalizedCacheObject>) {
  const selectedId = cache.readQuery<GetSelectedReservationId>({
    query: getSelectedReservationIdQuery,
  });
  if (selectedId) {
    return selectedId.selectedReservationId;
  }
  return null;
}

export function validateCanContinueToReservation(
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
) {
  const reservationId = getSelectedReservationId(cache);
  if (reservationId !== null) {
    const reservationKey = getCacheKey({
      __typename: 'Reservation',
      id: reservationId,
    });
    const reservation: canContinueToReservationFragment | null = cache.readFragment({
      fragment: canContinueToReservation,
      id: reservationKey,
    });
    if (reservation) {
      const canContinueToReservation =
        reservation.from && reservation.to && reservation.basket.setIds.length > 0;
      cache.writeData({ data: { canContinueToReservation } });
    }
  }
}

export function getActiveSetId(cache: ApolloCache<NormalizedCacheObject>, getCacheKey: any) {
  const selectedReservationId = getSelectedReservationId(cache);
  if (selectedReservationId !== null) {
    const reservationKey = getCacheKey({
      __typename: 'Reservation',
      id: selectedReservationId,
    });
    const cacheBasket: { basket: CacheBasket } | null = cache.readFragment({
      fragment: ReservationBasketFragment,
      id: reservationKey,
    });
    if (cacheBasket) {
      return cacheBasket.basket.activeSetId;
    }
  }
  return null;
}

export function getBasketSet(
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
  basketSetId?: number,
) {
  const basketKey = getBasketSetKey(cache, getCacheKey, basketSetId);
  if (basketKey) {
    const basketSet: CacheBasketSet | null = cache.readFragment({
      fragment: BasketSetFragment,
      id: basketKey,
    });
    if (basketSet) {
      return basketSet;
    }
  }
  return null;
}

export function getBasketSetKey(
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
  basketSetId?: number,
) {
  const setId = basketSetId || getActiveSetId(cache, getCacheKey);
  if (setId !== null) {
    return getCacheKey({ __typename: 'BasketSet', id: setId });
  }
  return null;
}

export function activateNextSet(cache: ApolloCache<NormalizedCacheObject>, getCacheKey: any) {
  const selectedReservationId = getSelectedReservationId(cache);
  if (selectedReservationId !== null) {
    const reservationKey = getCacheKey({ __typename: 'Reservation', id: selectedReservationId });
    const cacheBasket: { basket: CacheBasket } | null = cache.readFragment({
      fragment: ReservationBasketFragment,
      id: reservationKey,
    });
    if (cacheBasket) {
      let nextSetId = null;
      cacheBasket.basket.setIds.forEach((setId, idx, arr) => {
        if (
          setId === cacheBasket.basket.activeSetId &&
          idx < cacheBasket.basket.setIds.length - 1
        ) {
          nextSetId = arr[idx + 1];
        }
      });
      if (nextSetId) {
        activateSetItem(nextSetId, cache, getCacheKey);
      }
    }
  }
}

export function activateSetItem(
  setId: number,
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
  itemId?: number,
) {
  const selectedReservationId = getSelectedReservationId(cache);
  if (selectedReservationId !== null) {
    const reservationKey = getCacheKey({ __typename: 'Reservation', id: selectedReservationId });
    const cacheBasket: { basket: CacheBasket } | null = cache.readFragment({
      fragment: ReservationBasketFragment,
      id: reservationKey,
    });
    if (cacheBasket) {
      const basketSet = getBasketSet(cache, getCacheKey, setId);
      const basketKey = getBasketSetKey(cache, getCacheKey, setId);
      const basketQuery: GetBasket | null = cache.readQuery({ query: getBasket });
      const activebasket: GetActiveBasketSet | null = cache.readQuery({
        query: getActiveBasketSet,
      });
      if (basketSet && basketKey && basketQuery && activebasket) {
        const newCacheBasket = { ...cacheBasket };
        const newBasketQuery = JSON.parse(JSON.stringify(basketQuery));
        const newBasketSet: GetActiveBasketSet = JSON.parse(JSON.stringify(activebasket));
        const onlyUniversalItems = basketSet.items.every(item => item.useUniversal);

        if (!onlyUniversalItems) {
          newCacheBasket.basket.activeSetId = setId;
          newBasketQuery.basket.activeSetId = setId;
          newBasketSet.basketSet = basketSet;
          if (itemId !== undefined) {
            newBasketQuery.basket.sets = newBasketQuery.basket.sets.map((set: any) => {
              if (setId === set.id) {
                set.activeItem = itemId;
              }
              return set;
            });
            newBasketSet.basketSet.activeItem = itemId;
          }
        }

        cache.writeFragment({
          fragment: ReservationBasketFragment,
          id: reservationKey,
          data: newCacheBasket,
        });
        cache.writeQuery({
          query: getBasket,
          data: newBasketQuery,
        });
        cache.writeQuery({
          query: getActiveBasketSet,
          data: newBasketSet,
        });
        const reservationHeaderLabel = getReservationHeaderLabel(
          basketSet.type,
          basketSet.subType,
          basketSet.filters.sex,
          basketSet.items.find(item => item.id === itemId)?.type || basketSet.items[0].type,
        );
        cache.writeData({ data: { reservationHeaderLabel } });
      }
    }
  }
}

export function countReservationPrice(
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
  reservationId?: number,
) {
  const cacheBasket = getCacheBasket(cache, getCacheKey, reservationId);
  const reservationKey = getReservationKey(cache, getCacheKey);
  const reservation = cache.readFragment<{ customerId: number }>({
    fragment: customerReservationFragment,
    id: reservationKey,
  });
  const customer: { streetName: string | null } | null = cache.readFragment({
    fragment: customerInfo,
    id: `Customer:${reservation ? reservation.customerId : 0}`,
  });
  if (cacheBasket) {
    let rental = 0;
    let bail = 0;
    cacheBasket.basket.setIds.forEach(setId => {
      const basketSetKey = getBasketSetKey(cache, getCacheKey, setId);
      if (basketSetKey) {
        const basketPrice: { price: number; bailPrice: number } | null = cache.readFragment({
          fragment: basketSetPriceFragment,
          id: basketSetKey,
        });
        if (basketPrice) {
          rental += basketPrice.price;
          bail += basketPrice.bailPrice;
        }
      }
    });
    cache.writeData({
      data: {
        priceSum: {
          rental,
          bail,
          delivery: customer?.streetName ? 100 : 0,
          __typename: 'PriceSum',
        },
      },
    });
  }
}

export function getCacheBasket(
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
  reservationId?: number,
) {
  const reservationKey = getReservationKey(cache, getCacheKey, reservationId);
  if (reservationKey) {
    const cacheBasket: { basket: CacheBasket } | null = cache.readFragment({
      fragment: ReservationBasketFragment,
      id: reservationKey,
    });
    return cacheBasket;
  }
  return null;
}

export function getReservationKey(
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
  reservationId?: number,
) {
  const selectedReservationId = reservationId ? reservationId : getSelectedReservationId(cache);
  if (selectedReservationId !== null) {
    const reservationKey = getCacheKey({ __typename: 'Reservation', id: selectedReservationId });
    return reservationKey;
  }
  return null;
}

export function validateFinishReservation(
  cache: ApolloCache<NormalizedCacheObject>,
  getCacheKey: any,
  reservationId?: number,
) {
  const cachebasket = getCacheBasket(cache, getCacheKey, reservationId);
  if (cachebasket) {
    const finishReservation = cachebasket.basket.setIds
      .map(setId => {
        const basketSet = getBasketSet(cache, getCacheKey, setId);
        if (basketSet) {
          return basketSet.items
            .map(item => item.catalogItemId !== null)
            .reduce((acc, curr) => acc && curr, true);
        }
        return false;
      })
      .reduce((acc, curr) => acc && curr, true);
    cache.writeData({ data: { finishReservation } });
    return finishReservation;
  }
}

export function getReservationHeaderLabel(
  basketSetType: string,
  basketSetSubType: string,
  sex: string,
  itemType: string | undefined,
) {
  const reservationHeaderLabel =
    [
      getTranslation(basketSetType),
      getTranslation(
        basketSetSubType,
        basketSetSubType === BasketSubType.SET ? '' : basketSetType,
      ).toLowerCase(),
      getTranslation(sex).toLowerCase(),
    ].join(', ') +
    '|' +
    getTranslation(itemType || '', basketSetType);
  return reservationHeaderLabel;
}

function compareCatalogItems(a: CatalogItem, b: CatalogItem) {
  const fieldsToCompare = ['name', 'producer'];
  return fieldsToCompare
    .map(key => (a as any)[key] === (b as any)[key])
    .reduce((acc, curr) => acc && curr, true);
}

function reshapeData(data: CatalogItem[][]) {
  return data.map(items => {
    const image = items.find(item => item.imagePath);
    const description = items.find(item => item.description);
    const gruppedItem: GruppedCatalogItemType = {
      ...items[0],
      imagePath: image ? image.imagePath : null,
      description: description ? description.description : null,
      ids: items.map(item => item.id),
      sizes: items.map(item => item.size),
      prices: items.map(item => item.priceForInterval),
    };
    return gruppedItem;
  });
}

export function getGruppedAndReshepedCatalogItems(
  data: GetRecomendedItems_recomendedCatalogItems_items[] | undefined,
) {
  if (!data) {
    return null;
  }
  const gruppedData = groupWith(compareCatalogItems, data);
  return reshapeData(gruppedData);
}

export async function updatePricesForReservationUniversalItems(
  reservationId: string,
  { cache, getCacheKey, client }: ResolverContext,
) {
  const reservation = cache.readFragment<ReservationBasketFragmentType>({
    fragment: ReservationBasketFragment,
    id: reservationId,
  });
  if (reservation) {
    reservation.basket.setIds.forEach(setId => {
      const basketSet = getBasketSet(cache, getCacheKey, setId);
      if (basketSet) {
        updatePricesForSetUniversalItems(basketSet, { cache, getCacheKey, client });
      }
    });
  }
}

export async function updatePricesForSetUniversalItems(
  set: CacheBasketSet,
  { cache, getCacheKey, client }: ResolverContext,
) {
  const universalItems = set.items.filter(item => item.useUniversal);
  if (!universalItems.length) return [];
  const selectedReservationId = getSelectedReservationId(cache);
  if (selectedReservationId == null) return [];
  const id = getCacheKey({ __typename: 'Reservation', id: selectedReservationId });
  const reservationDuration: CacheReservationDuration | null = cache.readFragment({
    fragment: ReservationDurationFragment,
    id,
  });
  if (!reservationDuration || !reservationDuration.from || !reservationDuration.to) return [];
  let basketPrice = 0;
  let basketBail = 0;
  const nonUniversalsIDs =  await Promise.all(
    universalItems.map(async catalogItem => {
      const { data } = await client.query<GetRecomendedItems, GetRecomendedItemsVariables>({
        query: getRecomendedItems,
        variables: {
          ...omit(['afternoonPickup'], reservationDuration),
          morningPickUp: !reservationDuration.afternoonPickup,
          set: set.subType === BasketSubType.SET,
          input: {
            useUniversalCatalogItem: true,
            basketSetType: set.type,
            catalogItemType: catalogItem.type,
            sex: set.filters.sex,
            season: reservationDuration.season
          },
        },
        errorPolicy: 'ignore',
      });
      const id = getBasketCatalogItemKey(getCacheKey, catalogItem.id);
      if (!data || !data.recomendedCatalogItems.items.length) {
        cache.writeFragment({
          fragment: universalCatalogItem,
          id,
          data: { useUniversal: false, __typename: 'BasketCatalogItem' },
        });
        return catalogItem.id
      } else {
        const catalogItem = data.recomendedCatalogItems.items[0];
        basketPrice += catalogItem.priceForInterval;
        basketBail += catalogItem.priceForBail;
        cache.writeFragment({
          fragment: updateUniversalCatalogItem,
          id,
          data: {
            catalogItem: catalogItem,
            catalogItemId: catalogItem.id,
            price: catalogItem.priceForInterval,
            bailPrice: catalogItem.priceForBail,
            __typename: 'BasketCatalogItem',
          },
        });
      }
      return null;
    }),
  );
  if (~basketPrice) {
    const id = getBasketSetKey(cache, getCacheKey, set.id);
    cache.writeFragment({
      fragment: basketSetPriceFragment,
      id,
      data: {
        price: basketPrice,
        bailPrice: basketBail,
        __typename: 'BasketSet',
      },
    });

    countReservationPrice(cache, getCacheKey);
    validateFinishReservation(cache, getCacheKey);
  }
  return nonUniversalsIDs.filter(item => item && item > 0 ) as number[]
}

export function getBasketCatalogItemKey(getCacheKey: any, basketCatalogItem: number) {
  const reservationKey = getCacheKey({ __typename: 'BasketCatalogItem', id: basketCatalogItem });
  return reservationKey;
}

export function filterBasketValidation(basket: CacheBasketSet) {
  const { filters } = basket;
  const errors = [];

  if (basket.type === BasketSetType.SKI) {
    if ( !filters.fitness ) {
      errors.push(['fitness']);
    }
    if(!filters.age) {
      errors.push('age');
    }
  }
  if (basket.items.find(item => item.type === 'POLES')?.useUniversal) {
    if(!filters.height) {
      errors.push('height');
    }
  }
  if (basket.items.find(item => item.type === 'HEAD')?.useUniversal) {
    if(!filters.headSize) {
      errors.push('headSize');
    }
  }
  return errors;
}

export function areBasketFiltersValid(basket: CacheBasketSet) {
  const { filters } = basket;
  let valid = true;
  if (basket.type === BasketSetType.SKI) {
    valid = valid && !!filters.fitness;
    valid = valid && !!filters.age;
  }
  if (basket.items.find(item => item.type === 'POLES')?.useUniversal) {
    valid = valid && !!filters.height;
  }
  if (basket.items.find(item => item.type === 'HEAD')?.useUniversal) {
    valid = valid && !!filters.headSize;
  }
  return valid;
}

export async function validateBasketsFilters({ cache, getCacheKey, client }: ResolverContext) {
  const reservationKey = getReservationKey(cache, getCacheKey);
  if (!reservationKey) return;
  const reservation = client.readFragment<ReservationBasketFragmentType>({
    fragment: ReservationBasketFragment,
    id: reservationKey,
  });
  if (!reservation) return;
  const setIds = reservation.basket.setIds;
  const setFiltersValid = setIds.map(setId => {
    const setKey = getBasketSetKey(cache, getCacheKey, setId);
    const valid = cache.readFragment<FiltersValidFragmentType>({
      fragment: filtersValid,
      id: setKey,
    });
    return !!valid?.filtersValid;
  });
  const allValid = setFiltersValid.every(v => v);
  client.writeData({ data: { allBasketFiltersValid: allValid } });
}
