import {
  AddCatalogItemToSetVariables,
  GetBasket_basket_sets_items_catalogItem,
  SetBasketSetFilters,
  RemoveCatalogItemFromSetVariables,
} from 'graphql/types';
import {
  activateNextSet,
  activateSetItem,
  areBasketFiltersValid,
  countReservationPrice,
  getBasketSet,
  getBasketSetKey,
  validateBasketsFilters,
  validateFinishReservation,
} from 'lib/utils';
import { sum } from 'ramda';
import { BasketSetFragment, dataCatalogItemFragment } from './fragments';
import { ResolverContext } from './types';

export default {
  Query: {
    basketSet: (
      root: any,
      { basketSetId }: { basketSetId: number },
      { cache, getCacheKey }: ResolverContext,
    ) => {
      return getBasketSet(cache, getCacheKey, basketSetId);
    },
  },
  Mutation: {
    setBaksetSetFilters: (
      root: any,
      { input, basketSetId }: { input: SetBasketSetFilters; basketSetId: number },
      { cache, getCacheKey, client }: ResolverContext,
    ) => {
      const basketSet = getBasketSet(
        cache,
        getCacheKey,
        basketSetId || input.basketSetId || undefined,
      );
      const basketKey = getBasketSetKey(
        cache,
        getCacheKey,
        basketSetId || input.basketSetId || undefined,
      );
      if (basketSet && basketKey) {
        const newBasketSet = { ...basketSet };
        Object.entries(newBasketSet.filters).forEach(([key, value]) => {
          const val = (input as any)[key] !== undefined ? (input as any)[key] : value;
          (newBasketSet.filters as any)[key] = val;
        });
        newBasketSet.filtersValid = areBasketFiltersValid(newBasketSet);
        cache.writeFragment({
          fragment: BasketSetFragment,
          id: basketKey,
          data: newBasketSet,
        });
        if (newBasketSet.filtersValid) {
          validateBasketsFilters({ cache, getCacheKey, client });
        }
      }
    },
    addCatalogItemToSet: (
      root: any,
      { catalogItemId, basketSetId, basketItemId, price, bailPrice }: AddCatalogItemToSetVariables,
      { cache, getCacheKey }: ResolverContext,
    ) => {
      const finishReservationBefore = validateFinishReservation(cache, getCacheKey);
      const basketSet = getBasketSet(cache, getCacheKey, basketSetId || undefined);
      const basketKey = getBasketSetKey(cache, getCacheKey, basketSetId || undefined);
      if (basketSet && basketKey) {
        const newBasketSet = { ...basketSet };
        const withoutCatalogItem: number[] = [];
        newBasketSet.items = newBasketSet.items.map((item, idx, arr) => {
          if (item.id === basketItemId) {
            item.catalogItemId = catalogItemId;
            item.price = price;
            item.bailPrice = bailPrice;
          }
          if (!item.catalogItemId) {
            withoutCatalogItem.push(item.id);
          }
          return item;
        });
        newBasketSet.price = newBasketSet.items.reduce((acc, curr) => acc + curr.price, 0);
        newBasketSet.bailPrice = newBasketSet.items.reduce((acc, curr) => acc + curr.bailPrice, 0);
        if (withoutCatalogItem[0] != null) {
          newBasketSet.activeItem = withoutCatalogItem[0];
        } else {
          activateNextSet(cache, getCacheKey);
        }
        cache.writeFragment({ fragment: BasketSetFragment, id: basketKey, data: newBasketSet });
        countReservationPrice(cache, getCacheKey);
        const finishReservationAfter = validateFinishReservation(cache, getCacheKey);
        const redirectToSummary = !finishReservationBefore && finishReservationAfter;
        if (!redirectToSummary) {
          const catalogItemKey = getCacheKey({ __typename: 'CatalogItem', id: catalogItemId });
          const catalogItem: GetBasket_basket_sets_items_catalogItem | null = cache.readFragment({
            id: catalogItemKey,
            fragment: dataCatalogItemFragment,
          });
          if (catalogItem) {
            cache.writeData({
              data: { addedToBasketModal: `${catalogItem.producer} ${catalogItem.name}` },
            });
          }
        }
        return {
          redirectToSummary,
          __typename: 'AddCatalogItemToSetResult',
        };
      }
    },
    removeCatalogItemFromBasketSet: (
      root: any,
      { catalogItemId, basketSetId }: RemoveCatalogItemFromSetVariables,
      { cache, getCacheKey, client }: ResolverContext,
    ) => {
      const basketSet = getBasketSet(cache, getCacheKey, basketSetId || undefined);
      const basketKey = getBasketSetKey(cache, getCacheKey, basketSetId || undefined);
      if (basketSet && basketKey) {
        const newBasketSet = { ...basketSet };
        newBasketSet.items = newBasketSet.items.map(item => {
          if (item.catalogItemId === catalogItemId) {
            return {
              ...item,
              price: 0,
              bailPrice: 0,
              catalogItemId: null,
              catalogItem: null,
            };
          }
          return item;
        });
        newBasketSet.price = sum(newBasketSet.items.map(item => item.price));
        newBasketSet.bailPrice = sum(newBasketSet.items.map(item => item.bailPrice));
        cache.writeFragment({ fragment: BasketSetFragment, id: basketKey, data: newBasketSet });
        countReservationPrice(cache, getCacheKey);
        validateFinishReservation(cache, getCacheKey);
        validateBasketsFilters({ cache, getCacheKey, client });
        return true;
      }
      return false;
    },
    removeItemFromBasketSet: (
      root: any,
      { itemId, setId }: { itemId: number; setId: number },
      { cache, getCacheKey }: ResolverContext,
    ) => {
      const basketSet = getBasketSet(cache, getCacheKey, setId);
      const basketKey = getBasketSetKey(cache, getCacheKey, setId);
      if (basketSet && basketKey) {
        const indexOfItem = basketSet.items.findIndex(item => item.id === itemId);
        const newBasketSet = { ...basketSet };
        newBasketSet.items.splice(indexOfItem, 1);
        newBasketSet.price = sum(newBasketSet.items.map(item => item.price));
        newBasketSet.bailPrice = sum(newBasketSet.items.map(item => item.bailPrice));
        console.log({ newBasketSet });
        cache.writeFragment({ fragment: BasketSetFragment, id: basketKey, data: newBasketSet });
        countReservationPrice(cache, getCacheKey);
        validateFinishReservation(cache, getCacheKey);
      }
    },
    activateSetItem: (
      root: any,
      { itemId, setId }: { itemId: number; setId: number },
      { cache, getCacheKey }: ResolverContext,
    ) => {
      activateSetItem(setId, cache, getCacheKey, itemId);
    },
  },
};
