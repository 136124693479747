import { css } from '@emotion/core';
import styled from '@emotion/styled';
import colors from 'lib/colors';
import React from 'react';
import Box from './Box';
import Text, { TextProps } from './Text';

interface Props {
  name: string;
  checked: boolean;
  onChange: (id: string) => void;
  text: string;
  textProps?: TextProps;
  varinat?: 'small' | 'big';
  disabled?: boolean;
}

const Checkbox = ({
  name,
  text,
  checked,
  onChange,
  textProps,
  varinat = 'small',
  disabled = false,
}: Props) => {
  const handleChange = () => {
    onChange(name);
  };

  return (
    <Label>
      {/*  Nedd to use color preperty in parent, but there is some problem with TextColorProps  */}
      <Text css={css({ marginTop: varinat === 'big' ? '2px ' : '0px' })} {...(textProps as any)}>
        {text}
      </Text>
      <Check type="checkbox" checked={checked} onChange={handleChange} />
      <Box width="24px">
        <CustomCheck varinat={varinat} checked={checked} disabled={disabled} />
      </Box>
    </Label>
  );
};

export default Checkbox;

interface ICheckBoxProps {
  varinat: 'small' | 'big';
  checked: boolean;
  disabled: boolean;
}

const Label = styled.label`
  display: flex;
`;

const CustomCheck = styled.span<ICheckBoxProps>(props => ({
  width: props.varinat === 'small' ? 16 : 22,
  height: props.varinat === 'small' ? 16 : 22,
  marginLeft: props.varinat ? 6 : 12,
  border: props.varinat === 'small' ? `1px solid` : 'none',
  borderColor: props.checked
    ? props.disabled
      ? colors.border
      : colors.primaryDark
    : props.disabled
    ? colors.border
    : colors.greyDark,
  position: 'absolute',
  backgroundColor:
    props.varinat === 'small' && props.checked
      ? props.disabled
        ? colors.border
        : colors.primary
      : 'white',
  opacity: props.disabled ? 0.8 : 1,
  ':after': {
    position: 'absolute',
    left: props.varinat === 'small' ? 5 : 8,
    top: props.varinat === 'small' ? 1 : 2,
    content: '""',
    display: props.checked ? 'block' : 'none',
    width: props.varinat === 'small' ? 5 : 7,
    height: props.varinat === 'small' ? 10 : 14,
    border: 'solid black',
    borderColor: props.varinat === 'small' ? colors.white : colors.primary,
    borderWidth: '0px 2px 2px 0px',
    transform: 'rotate(45deg)',
  },
}));

const Check = styled.input({
  display: 'none',
  position: 'absolute',
  opacity: 0,
  cursor: 'pointer',
  height: 0,
  width: 0,
});
