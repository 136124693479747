import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import { globalHistory, Router } from '@reach/router';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ErrorBoundary, NotFound } from 'components';
import GopayOrderStatus from 'components/GopayOrderStatus';
import LoadingModal from 'components/LoadingModal';
import OrderError from 'components/OrderError';
import OrderPlaced from 'components/OrderPlaced';
import PageWrapper from 'components/PageWrapper';
import { ThemeProvider } from 'emotion-theming';
import cacheInitalData from 'graphql/initData';
import moment from 'moment';
import Maintance from 'pages/Maintance';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { materialTheme, theme } from 'theme';
import createClient from './graphql/client';


const Home = lazy(() => import('./pages/Home/Home'));
const Reservation = lazy(() => import('./pages/Reservation/Reservation'));
const BankRedirecting = lazy(() => import('./pages/BankRedirecting'));
const ReservationSummary = lazy(() => import('./pages/ReservationSummary/ReservationSummary'));

const App: React.FC = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>(undefined);

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ locationChange: location.pathname });
    });
  }, []);

  useEffect(() => {
    createClient().then(newClient => {
      const initData = cacheInitalData;
      const cacheData = newClient.cache.extract();
      // TODO: change id back to !==
      if (!cacheData.ROOT_QUERY || cacheData.ROOT_QUERY.version !== initData.version) {
        newClient.resetStore().then(() => {
          newClient.writeData({
            data: initData,
          });
        });
      } else {
        // we will do some magic staff here later
      }
      newClient.onResetStore(async () => newClient.writeData({ data: initData }));
      setClient(newClient);
    });
    return () => {};
  }, []);

  if (client === undefined)
    return (
      <PageWrapper hideFooter simpleHeader>
        <LoadingModal />
      </PageWrapper>
    );

    moment.updateLocale('cs', require(`moment/locale/cs`));
    return (
      <ApolloProvider client={client}>
        <MaterialThemeProvider theme={materialTheme}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <Suspense fallback={<div />}>
              <Router>
                <Home path="/" />
                <Reservation path="/reservation" />
                <ReservationSummary path="/reservation-summary" />
                <OrderPlaced path="/order-placed/:orderId" />
                <GopayOrderStatus path="/gopay-return" />
                <OrderPlaced path="/order-placed/" />
                <OrderError path="order-error" />
                <BankRedirecting path="/bank-redirecting"/>
                <Maintance path="/maintenance" />
                <NotFound default />
              </Router>
            </Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </MaterialThemeProvider>
    </ApolloProvider>
  );
};

export default App;
