import css from '@emotion/css';
import styled from '@emotion/styled';
import colors from 'lib/colors';
import React from 'react';
import { useUID } from 'react-uid';
import Modal from './Modal';

interface Props {
  title?: string;
}

const LoadingModal = ({ title }: Props) => {
  const modalId = useUID();

  return (
    <Modal
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      aria={{
        labelledby: modalId,
      }}
      css={css({ '.Modal': { overflow: 'hidden', background: 'transparent' } })}
    >
      <Center>
        <SpinnigLoader />
      </Center>
      {title != null && <Text>{title}</Text>}
    </Modal>
  );
};

export default LoadingModal;

const SpinnigLoader = styled.div({
  width: 70,
  height: 70,
  borderRadius: '50%',
  border: `5px solid ${colors.primaryLight}`,
  borderLeftColor: colors.primary,
  backgroundColor: 'transparent',
  animation: 'rotate-s-loader 1s linear infinite',
  margin: 'margin: 6rem auto',
  '@keyframes rotate-s-loader': {
    from: {
      transform: 'rotate(0)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.span`
  margin-top: 32px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
`;
