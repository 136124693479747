import { useMutation } from '@apollo/react-hooks';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { Box, Text } from 'components';
import Button from 'components/Button';
import Flex from 'components/Flex';
import {
  RemoveSet,
  activateSetItem,
  getActiveBasketSet,
  getBasket,
  removeItemFromSet,
} from 'graphql/queries';
import {
  ActivateSetItem,
  ActivateSetItemVariables,
  BasketSubType,
  CatalogItemType,
  GetBasket_basket_sets,
  RemoveItemFromSet,
  RemoveItemFromSetVariables,
  RemoveSetFromBasket,
  RemoveSetFromBasketVariables,
} from 'graphql/types';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import colors from 'lib/colors';
import { getTranslation } from 'lib/translations';
import { stylePrice } from 'lib/utils';
import React from 'react';
import { ReactComponent as CheckedSvg } from '../../assets/checked2.svg';

interface IProps {
  set: GetBasket_basket_sets;
  active: boolean;
  reservationBasketSet: boolean;
  canBeRemoved: boolean;
  onRemoveItem: () => void;
}

const BasketSet: React.FC<IProps> = ({ reservationBasketSet, active, set, ...props }) => {
  const [removeSetFromBasket] = useMutation<RemoveSetFromBasket, RemoveSetFromBasketVariables>(
    RemoveSet,
  );
  const [activateItem] = useMutation<ActivateSetItem, ActivateSetItemVariables>(activateSetItem);
  const [removeItem] = useMutation<RemoveItemFromSet, RemoveItemFromSetVariables>(
    removeItemFromSet,
  );
  const { trackEvent } = useGoogleAnalytics();

  const handleRemoveButtonClick = () => {

    removeSetFromBasket({
      variables: { setId: set.id },
      refetchQueries: [{ query: getBasket }, { query: getActiveBasketSet }],
    });
    props.onRemoveItem();
    trackEvent('remove_from_cart', {
      currency: 'CZK',
      value: set.price,
      items: [
        {
          item_id: set.type + '_' + set.subType,
          item_name: set.type + '-' + set.subType,
        },
      ],
    });
  };

  const handleItemClick = (itemId: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    activateItem({ variables: { itemId, setId: set.id } });
  };

  const handleRemoveItem = (itemId: number) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    removeItem({ variables: { itemId, setId: set.id } });
    activateItem({ variables: { itemId: set.items[0].id, setId: set.id } });
  };

  return (
    <Box
      css={css`
        border: 1px solid ${reservationBasketSet && active ? colors.primary : colors.borderGrey};
        position: relative;
        padding: 8px 16px 8px 24px;
      `}
    >
      {(!reservationBasketSet || (reservationBasketSet && props.canBeRemoved)) && (
        <CrossButton onClick={handleRemoveButtonClick} label={<span>&#10005;</span>} big={true} />
      )}
      <Box>
        <Text>
          {getTranslation(set.type)}, <br />
          {getTranslation(set.subType, set.subType === BasketSubType.SET ? '' : set.type)}
        </Text>
      </Box>
      <Box mt={3}>
        <Text fontWeight="lighter">{getTranslation(set.filters.sex)}</Text>
      </Box>
      {reservationBasketSet && (
        <Box mt={3}>
          {set.items.map(item => {
            const isActive = item.id === set.activeItem && active;
            const color = isActive
              ? colors.primary
              : item.catalogItemId
              ? colors.black
              : colors.greyDark;
            let canBeRemoved = set.items.length > 1;
            if (canBeRemoved && set.subType === BasketSubType.SET) {
              canBeRemoved = set.items.length > 2;
              if (canBeRemoved) {
                canBeRemoved = !(item.type === CatalogItemType.MAIN_ITEM);
              }
            }
            const itemSelectable = !item.useUniversal;
            let params = itemSelectable
              ? {
                  role: 'button',
                  onClick: handleItemClick(item.id),
                }
              : {};
            return (
              <Flex
                key={item.type}
                marginY={[2]}
                justifyContent={'space-between'}
                css={itemSelectable && { cursor: 'pointer' }}
              >
                <Flex {...params}>
                  {item.catalogItemId && (
                    <Box>
                      <CheckedSvg css={css({ marginLeft: '-16px' })} />
                    </Box>
                  )}
                  <Text
                    css={css({ borderBottom: isActive ? `1px solid ${colors.primary}` : 'none' })}
                    fontSize={[14]}
                    color={color}
                    title={
                      item?.catalogItem?.name && item?.catalogItem?.producer
                        ? item?.catalogItem?.name + ' ' + item?.catalogItem?.producer
                        : ''
                    }
                  >
                    {getTranslation(item.type, set.type)}
                  </Text>
                </Flex>
                {canBeRemoved && (
                  <CrossButton
                    color={color}
                    onClick={handleRemoveItem(item.id)}
                    label={<span>&#10005;</span>}
                    big={false}
                  />
                )}
              </Flex>
            );
          })}
          <Flex mt={3} justifyContent={'space-between'}>
            <Text fontSize={14}>CENA: </Text>
            <Text fontSize={14} fontWeight={'bolder'}>
              {stylePrice(set.price)}
            </Text>
          </Flex>
          <Flex mt={3} justifyContent={'space-between'}>
            <Text fontSize={14}>KAUCE: </Text>
            <Text fontSize={14} fontWeight={'bolder'}>
              {stylePrice(set.bailPrice)}
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default BasketSet;

const CrossButton = styled(Button)<{ big: boolean; color?: string }>(props => ({
  color: props.color ? props.color : colors.black,
  fontSize: props.big ? 24 : 16,
  fontWeight: 'lighter',
  position: props.big ? 'absolute' : 'initial',
  right: 5,
  top: 0,
  padding: 0,
}));
