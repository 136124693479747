import { useMutation } from '@apollo/react-hooks';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Button, Flex, Text } from 'components';
import { setBasketModalOpen, getBasket } from 'graphql/queries';
import { SetBasketModalOpen, SetBasketModalOpenVariables, GetBasket } from 'graphql/types';
import colors from 'lib/colors';
import React from 'react';
import QueryComponent from './QueryComponent';

const BasketButton: React.FC = () => {
  const [setModalOpen] = useMutation<SetBasketModalOpen, SetBasketModalOpenVariables>(
    setBasketModalOpen,
  );
  const handleBasketButtonClick = () => {
    setModalOpen({ variables: { open: true } });
  };

  return (
    <Flex display={['flex', 'flex', 'flex', 'none']} css={css({ marginTop: 24 })}>
      <QueryComponent<GetBasket> query={getBasket}>
        {({ data }) => (
          <Circle>
            <Text>{data.basket.sets.length > 9 ? '9+' : data.basket.sets.length}</Text>
          </Circle>
        )}
      </QueryComponent>
      <Button
        onClick={handleBasketButtonClick}
        css={css({
          color: colors.white,
        })}
        label="Košík"
      />
    </Flex>
  );
};

export default BasketButton;

const Circle = styled('div')({
  marginTop: 4,
  marginRight: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.red,
  color: colors.white,
  fontSize: 14,
  width: 24,
  height: 24,
  borderRadius: '50%',
});
