import { css } from '@emotion/core';
import styled from '@emotion/styled';
import colors from 'lib/colors';
import React from 'react';
import Flex from './Flex';

interface Props {
  items: { id: string; name: string }[];
  onSelect: (id: string) => void;
  selected: string | null;
  disabled?: boolean;
  fullWidth?: boolean;
  separate?: number[];
}

const SelectItem = ({
  items,
  onSelect,
  selected,
  disabled = false,
  fullWidth = false,
  separate,
}: Props) => {
  const separateStyles = separate
    ? separate.map(
        index =>
          `:nth-of-type(${index - 1}) {
      border-right: 1px solid ${colors.border};
    }
    :nth-of-type(${index}) {
      margin-left: 20px;
    }`,
      )
    : [''];

  return (
    <Flex flexWrap={fullWidth ? 'wrap' : 'nowrap'} width="100%" justifyContent="center">
      {items.map(item => (
        <Flex
          width={fullWidth ? `${100 / items.length}%` : ''}
          key={item.id}
          css={css`
            :last-child {
              border-right: 1px solid ${colors.border};
            }
            ${separateStyles.join('')}
          `}
        >
          <Button
            isSelected={item.id === selected}
            disabled={disabled}
            onClick={onSelect}
            item={item}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default SelectItem;

interface ButtonProp {
  item: { id: string; name: string };
  onClick: (id: string) => void;
  isSelected: boolean;
  disabled: boolean;
}

const Button = ({ item, onClick, isSelected, disabled }: ButtonProp) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick(item.id);
  };

  return (
    <StyledButton onClick={handleClick} selected={isSelected} disabled={disabled}>
      {item.name}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ selected: boolean; disabled: boolean }>`
  color: ${props =>
    props.selected ? colors.white : props.disabled ? colors.border : colors.black};
  border-radius: 0;
  padding: 8px;
  background: ${props =>
    props.selected ? (props.disabled ? colors.border : colors.primary) : colors.white};
  width: 100%;
  font-size: 14px;
  outline: none;
  border: ${`1px solid ${colors.border}`};
  border-right: none;
  &:disabled {
    pointer-events: none;
  }
`;
