import dummySki from '../assets/dummySki.jpg';
import dummyCCS from '../assets/dummyCCS.jpg';
import dummySnowboard from '../assets/dummySnowboard.jpg';
import dummyBoots from '../assets/dummyBoots.jpg';
import { GruppedCatalogItem } from 'lib/types';

export function isNotNull<T>(value: T | null | undefined): value is T {
  return value != null;
}

export function getRecommendedItemsDummy(): GruppedCatalogItem[] {
  return [
    {
      id: 1,
      ids: [1],
      sizes: ['165cm'],
      prices: [1100],
      name: 'REDSTER S9 FIS',
      description: 'Sjezdové slalomky pro sportovní a závodní lyžování na upravené sjezdovce.',
      producer: 'Atomic',
      priceForInterval: 1100,
      priceForBail: 100,
      imagePath: dummySki,
      __typename: 'CatalogItem',
    },
    {
      id: 2,
      ids: [2],
      sizes: ['196'],
      prices: [880],
      name: 'SALOMON S RACE ESKIN',
      description:
        'Běžky pro závodní lyžování. Mají tichý a rychlý skluz a jsou vhodné pro celodenní ježdění.',
      producer: 'Salomon',
      priceForInterval: 880,
      priceForBail: 100,
      imagePath: dummyCCS,
      __typename: 'CatalogItem',
    },
    {
      id: 3,
      ids: [3],
      sizes: ['160'],
      prices: [600],
      name: 'SALOMON WILD CARD',
      description: 'Snowboard s dřevěným jádrem vhodný na všechny typy sjezdovek.',
      producer: 'Salomon',
      priceForInterval: 600,
      priceForBail: 100,
      imagePath: dummySnowboard,
      __typename: 'CatalogItem',
    },
    {
      id: 4,
      ids: [4],
      sizes: ['29,5cm'],
      prices: [],
      name: 'ATOMIC HAWX PRIME R100',
      description:
        'Pánské sjezdové boty pro rekreační a pokročilejší lyžaře. Univerzální bota s větší tvrdostí pro sportovnější jízdu. Užší konstrukce je určena pro užší tvar nohy.',
      producer: 'Atomic',
      priceForInterval: 0,
      priceForBail: 100,
      imagePath: dummyBoots,
      __typename: 'CatalogItem',
    },
  ];
}
