import {
  BasketSetType,
  BasketSubType,
  CatalogItemType,
  CCSPurpose,
  CCSStyle,
  ChildSex,
  Fitness,
  Sex,
  SkiArc,
  Skills,
  SkiSlope,
  SnowboardPosition,
} from 'graphql/types';
import { ButtonsGroupFilterType, FormInputType, InputFilterType, SliderFilterType } from './enums';

export function getTranslation(term: string, contextTerm?: string) {
  switch (term) {
    case InputFilterType.FOOTSIZE:
      return 'Délka chodidla';
    case InputFilterType.AGE:
      return 'Věk';
    case ButtonsGroupFilterType.SKILLS:
      return 'Zkušenosti';
    case ButtonsGroupFilterType.SKISLOPE:
      return 'Sjezdovka';
    case ButtonsGroupFilterType.SKIARC:
      return 'Styl jízdy';
    case ButtonsGroupFilterType.CCSSTYLE:
      return 'Styl';
    case ButtonsGroupFilterType.CCSPURPOSE:
      return 'Účel jízdy';
    case ButtonsGroupFilterType.CHILDSEX:
      return 'Dítě';
    case ButtonsGroupFilterType.SNOWBOARD_POSITION:
      return 'Postavení nohou';
    case SnowboardPosition.LEFT_FRONT:
      return 'Levá vpředu';
    case SnowboardPosition.RIGHT_FRONT:
      return 'Pravá vpředu';
    case ChildSex.GIRL:
      return 'Holka';
    case ChildSex.BOY:
      return 'Kluk';
    case CCSPurpose.SKIN:
      return 'Skin - mohérový pás';
    case CCSPurpose.GRIP:
      return 'Grip - šupiny';
    case CCSStyle.KLASIC:
      return 'Klasika';
    case CCSStyle.SCATE:
      return 'Skate';
    case SkiArc.LONG:
      return 'Dlouhý';
    case SkiArc.SHORT:
      return 'Krátký';
    case SkiArc.UNIVERSAL:
      return 'Univerzální';
    case SkiSlope.FREE:
      return 'Neupravená';
    case SkiSlope.GROOMED:
      return 'Upravená';
    case Skills.BEGINNER:
      return 'Začátečník';
    case Skills.RECREATIONAL:
      return 'Rekreační';
    case Skills.SPORT:
      return 'Sportovní';
    case Skills.FREERIDE:
      return 'Freeride';
    case Fitness.BEGINNER:
      return 'Začátečník';
    case Fitness.RECREATIONAL:
      return 'Rekreační';
    case Fitness.SPORT:
      return 'Sportovní';
    case SliderFilterType.HEIGHT:
      return 'Výška';
    case SliderFilterType.HEADSIZE:
      return 'Obvod hlavy';
    case SliderFilterType.WEIGHT:
      return 'Váha';
    case BasketSetType.CCS:
      return 'Běžky';
    case BasketSetType.SKI:
      return 'Lyže';
    case BasketSetType.SKIALP_FREERIDE:
      return 'Skialp';
    case BasketSetType.SNOWBOARD:
      return 'Snowboard';
    case BasketSubType.SET:
      switch (contextTerm) {
        case BasketSetType.CCS:
          return 'Běžecký set';
        case BasketSetType.SKI:
          return 'Lyžařský set';
        case BasketSetType.SKIALP_FREERIDE:
          return 'Skialp set';
        case BasketSetType.SNOWBOARD:
          return 'Snowboardový set';
        default:
          return 'set';
      }
    case BasketSubType.WITHOUTACCESORIES:
      switch (contextTerm) {
        case BasketSetType.CCS:
          return 'Pouze běžky';
        case BasketSetType.SKI:
          return 'Pouze lyže';
        case BasketSetType.SKIALP_FREERIDE:
          return 'Pouze lyže';
        case BasketSetType.SNOWBOARD:
          return 'Pouze snowboard';
        default:
          return term;
      }
    case BasketSubType.ONLYACCESORIES:
      return 'Pouze přislušenství';
    case Sex.MAN:
      return 'Muž';
    case Sex.WOMAN:
      return 'Žena';
    case Sex.CHILD:
      return 'Dítě';
    case CatalogItemType.BOOTS:
      return 'Boty';
    case CatalogItemType.BACKPACK:
      return 'Batoh';
    case CatalogItemType.BAG_WITH_ABS:
      return 'Batoh s ABS';
    case CatalogItemType.FINDER:
      return 'Vyhledávač';
    case CatalogItemType.HEAD:
      return 'Helma';
    case CatalogItemType.POLES:
      return 'Hůlky';
    case CatalogItemType.BAG:
      return 'Vak';
    case CatalogItemType.MAIN_ITEM: {
      switch (contextTerm) {
        case BasketSetType.SKI:
        case BasketSetType.CCS:
        case BasketSetType.SKIALP_FREERIDE:
          return 'Lyže';
        case BasketSetType.SNOWBOARD:
          return 'Snowboard';
      }
      return term;
    }
    case FormInputType.DELIVERY:
      return 'Doručení na adresu';
    case FormInputType.DELIVERY_FORENOON:
      return 'Objednávka dopoledne';
    case FormInputType.DELIVERY_AFTERNOON:
      return 'Objednávka odpoledne';
    case FormInputType.DELIVERY_WEEKEND:
      return 'Objednávka na víkend';
    case FormInputType.DELIVERY_FRIDAY:
      return 'Objednávka v pátek';
    default:
      return term;
  }
}
