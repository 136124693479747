import { css } from '@emotion/core';
import colors from 'lib/colors';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

type ReactModalAdapterProps = ReactModal['props'] & {
  className?: string;
  modalClassName?: string | ReactModal.Classes;
};
const ReactModalAdapter = ({ className, modalClassName, ...props }: ReactModalAdapterProps) => (
  <ReactModal className={modalClassName} portalClassName={className} {...props} />
);

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

type ModalProps = Omit<ReactModal['props'], 'overlayClassName' | 'modalClassName'> & {
  className?: string;
  type?: string;
};

const Modal = (props: ModalProps) => {
  useEffect(() => {
    if (props.isOpen && props.type === 'BasketModal') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  });

  return (
    <ReactModalAdapter
      {...props}
      overlayClassName="Overlay"
      modalClassName="Modal"
      css={css`
        .Modal {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #d8d8d8;
          overflow: auto;
          --webkit-overflow-scrolling: touch;
          outline: none;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          background-color: ${colors.white};
          border-radius: 0;
        }

        .Overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 1;
        }
      `}
    />
  );
};

export default Modal;
