import { SetCustomerInfoVariables, SetDeliveryInfoVariables } from 'graphql/types';
import { countReservationPrice, getReservationKey } from 'lib/utils';
import { customerInfo, customerReservationFragment } from './fragments';
import { CacheCustomer, ResolverContext } from './types';

interface CustomerInfoFragmentType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  streetName: string;
  streetNumber: string;
  city: string;
  zipCode: string;
}

export default {
  Query: {
    getCustomerInfo: (root: any, args: any, { cache, getCacheKey }: ResolverContext) => {
      const reservationKey = getReservationKey(cache, getCacheKey);
      const reservation = cache.readFragment<{ customerId: number }>({
        fragment: customerReservationFragment,
        id: reservationKey,
      });
      if (reservation) {
        const customerKey = getCacheKey({ __typename: 'Customer', id: reservation.customerId });
        return cache.readFragment({ fragment: customerInfo, id: customerKey });
      }
    }
  },
  Mutation: {
    setCustomerInfo: (
      root: any,
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        customerId: recievedCustomerId,
      }: SetCustomerInfoVariables,
      { cache, getCacheKey }: ResolverContext,
    ) => {
      let customerId = recievedCustomerId;
      if (!customerId) {
        const reservationKey = getReservationKey(cache, getCacheKey);
        const reservation = cache.readFragment<{ customerId: number} | null>({
          fragment: customerReservationFragment,
          id: reservationKey,
        });
        if (reservation) customerId = reservation.customerId;
      }
      if (customerId !== null && customerId !== undefined) {
        const customerCacheId = getCacheKey({ id: customerId, __typename: 'Customer' })
        const name = `Customer_${customerId}`;
        const data = cache.readFragment<CustomerInfoFragmentType>({ fragment: customerInfo, id: customerCacheId });
        const newCustomer: { [x: string]: CacheCustomer } = {
          [name]: {
            zipCode: data && data.zipCode,
            streetNumber: data && data.streetNumber,
            streetName: data && data.streetName,
            city: data && data.city,
            firstName,
            lastName,
            email,
            phoneNumber,
            id: customerId,
            __typename: 'Customer',
          },
        };
        cache.writeData({ data: newCustomer });
      }
    },
    setDeliveryInfo: (
      root: any,
      {
        streetNumber,
        streetName,
        city,
        zipCode,
        customerId: recievedCustomerId,
      }: SetDeliveryInfoVariables,
      { cache, getCacheKey, client }: ResolverContext,
    ) => {
      let customerId = recievedCustomerId;
      if (!customerId) {
        const reservationKey = getReservationKey(cache, getCacheKey);
        const reservation = cache.readFragment<{ customerId: number }>({
          fragment: customerReservationFragment,
          id: reservationKey,
        });
        if (reservation) customerId = reservation.customerId;
      }
      if (customerId !== null && customerId !== undefined) {
        const customerCacheId = getCacheKey({ id: customerId, __typename: 'Customer' })
        const name = `Customer_${customerId}`;
        const data = cache.readFragment<CustomerInfoFragmentType>({ fragment: customerInfo, id: customerCacheId });
        const newCustomer: { [x: string]: CacheCustomer } = {
          [name]: {
            firstName: (data && data.firstName) || "",
            lastName: (data && data.lastName) || "",
            email: (data && data.email) || "",
            phoneNumber: (data && data.phoneNumber) || "",
            zipCode: zipCode || null,
            city: city || null,
            streetName: streetName || null,
            streetNumber: streetNumber || null,
            id: customerId,
            __typename: 'Customer',
          },
        };

        cache.writeData({ data: newCustomer })
        countReservationPrice(cache, getCacheKey)
      }
    },
  },
};
