/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReservationDateRange
// ====================================================

export interface GetReservationDateRange_reservation {
  __typename: "Reservation";
  from: string | null;
  to: string | null;
  season: boolean;
  afternoonPickup: boolean;
}

export interface GetReservationDateRange {
  reservation: GetReservationDateRange_reservation;
}

export interface GetReservationDateRangeVariables {
  reservationId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDateRange
// ====================================================

export interface SetDateRange_setReservationDateRange {
  __typename: "Reservation";
  from: string | null;
  to: string | null;
  season: boolean;
  afternoonPickup: boolean;
}

export interface SetDateRange {
  setReservationDateRange: SetDateRange_setReservationDateRange;
}

export interface SetDateRangeVariables {
  reservationId?: number | null;
  from?: string | null;
  to?: string | null;
  season: boolean;
  afternoonPickup: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PhoneValidate
// ====================================================

export interface PhoneValidate {
  phoneValidate: string | null;
}

export interface PhoneValidateVariables {
  number: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddToBasket
// ====================================================

export interface AddToBasket_addSetToBasket {
  __typename: "BasketSet";
  activeItem: number | null;
}

export interface AddToBasket {
  addSetToBasket: AddToBasket_addSetToBasket;
}

export interface AddToBasketVariables {
  input: AddSetToBasketInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSetFromBasket
// ====================================================

export interface RemoveSetFromBasket {
  removeSetFromBasket: boolean | null;
}

export interface RemoveSetFromBasketVariables {
  reservationId?: number | null;
  setId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearBasket
// ====================================================

export interface ClearBasket {
  clearBasket: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClosedDays
// ====================================================

export interface ClosedDays_closedDays {
  __typename: "ClosedDay";
  id: number;
  from: any;
  to: any;
  recurring: RecurringType;
  note: string | null;
}

export interface ClosedDays {
  closedDays: (ClosedDays_closedDays | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBasket
// ====================================================

export interface GetBasket_basket_sets_filters {
  __typename: "WizardFilters";
  sex: Sex;
  height: number | null;
  weight: number | null;
  age: number | null;
  fitness: Fitness | null;
  headSize: number | null;
  snowboardPosition: SnowboardPosition | null;
}

export interface GetBasket_basket_sets_items_catalogItem {
  __typename: "CatalogItem";
  id: number;
  name: string;
  producer: string;
}

export interface GetBasket_basket_sets_items {
  __typename: "BasketCatalogItem";
  id: number;
  type: CatalogItemType;
  useUniversal: boolean;
  bailPrice: number;
  catalogItemId: number | null;
  catalogItem: GetBasket_basket_sets_items_catalogItem | null;
}

export interface GetBasket_basket_sets {
  __typename: "BasketSet";
  id: number;
  type: BasketSetType;
  subType: BasketSubType;
  activeItem: number | null;
  price: number;
  bailPrice: number;
  filters: GetBasket_basket_sets_filters;
  items: GetBasket_basket_sets_items[];
}

export interface GetBasket_basket {
  __typename: "Basket";
  activeSetId: number | null;
  sets: GetBasket_basket_sets[];
}

export interface GetBasket {
  basket: GetBasket_basket;
}

export interface GetBasketVariables {
  reservationId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActiveBasketSet
// ====================================================

export interface GetActiveBasketSet_basketSet_filters {
  __typename: "WizardFilters";
  sex: Sex;
  height: number | null;
  weight: number | null;
  age: number | null;
  fitness: Fitness | null;
  footSize: number | null;
  skiSlope: SkiSlope | null;
  skiArc: SkiArc | null;
  skills: Skills | null;
  ccsStyle: CCSStyle | null;
  ccsPurpose: CCSPurpose | null;
  childSex: ChildSex | null;
  snowboardPosition: SnowboardPosition | null;
  headSize: number | null;
}

export interface GetActiveBasketSet_basketSet_items {
  __typename: "BasketCatalogItem";
  id: number;
  type: CatalogItemType;
  catalogItemId: number | null;
  useUniversal: boolean;
}

export interface GetActiveBasketSet_basketSet {
  __typename: "BasketSet";
  id: number;
  type: BasketSetType;
  subType: BasketSubType;
  filters: GetActiveBasketSet_basketSet_filters;
  items: GetActiveBasketSet_basketSet_items[];
  activeItem: number | null;
}

export interface GetActiveBasketSet {
  basketSet: GetActiveBasketSet_basketSet | null;
}

export interface GetActiveBasketSetVariables {
  basketSetId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRecomendedItems
// ====================================================

export interface GetRecomendedItems_recomendedCatalogItems_items {
  __typename: "CatalogItem";
  id: number;
  name: string;
  size: string;
  producer: string;
  imagePath: string | null;
  description: string | null;
  priceForInterval: number;
  priceForBail: number;
}

export interface GetRecomendedItems_recomendedCatalogItems {
  __typename: "RecomendedCatalogItemsResult";
  items: GetRecomendedItems_recomendedCatalogItems_items[];
}

export interface GetRecomendedItems {
  recomendedCatalogItems: GetRecomendedItems_recomendedCatalogItems;
}

export interface GetRecomendedItemsVariables {
  input: RecomendedCatalogItemFilter;
  from: any;
  to: any;
  set: boolean;
  season: boolean;
  morningPickUp: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllSearchItems
// ====================================================

export interface GetAllSearchItems_allSearchItems_items {
  __typename: "CatalogItem";
  id: number;
  name: string;
  size: string;
  producer: string;
  imagePath: string | null;
  description: string | null;
  priceForInterval: number;
  priceForBail: number;
}

export interface GetAllSearchItems_allSearchItems {
  __typename: "RecomendedCatalogItemsResult";
  items: GetAllSearchItems_allSearchItems_items[];
}

export interface GetAllSearchItems {
  allSearchItems: GetAllSearchItems_allSearchItems;
}

export interface GetAllSearchItemsVariables {
  input: AllCatalogItemFilter;
  from: any;
  to: any;
  set: boolean;
  season: boolean;
  morningPickUp: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSearchString
// ====================================================

export interface GetSearchString {
  searchString: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBasketSummary
// ====================================================

export interface GetBasketSummary_basket_sets_filters {
  __typename: "WizardFilters";
  footSize: number | null;
  weight: number | null;
  height: number | null;
  age: number | null;
  fitness: Fitness | null;
  headSize: number | null;
}

export interface GetBasketSummary_basket_sets_items_catalogItem {
  __typename: "CatalogItem";
  id: number;
  imagePath: string | null;
  name: string;
  producer: string;
  size: string;
}

export interface GetBasketSummary_basket_sets_items {
  __typename: "BasketCatalogItem";
  id: number;
  type: CatalogItemType;
  catalogItemId: number | null;
  price: number;
  useUniversal: boolean;
  bailPrice: number;
  catalogItem: GetBasketSummary_basket_sets_items_catalogItem | null;
}

export interface GetBasketSummary_basket_sets {
  __typename: "BasketSet";
  id: number;
  type: BasketSetType;
  subType: BasketSubType;
  price: number;
  bailPrice: number;
  filters: GetBasketSummary_basket_sets_filters;
  items: GetBasketSummary_basket_sets_items[];
}

export interface GetBasketSummary_basket {
  __typename: "Basket";
  sets: GetBasketSummary_basket_sets[];
}

export interface GetBasketSummary {
  basket: GetBasketSummary_basket;
}

export interface GetBasketSummaryVariables {
  reservationId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCatalogItemFromSet
// ====================================================

export interface RemoveCatalogItemFromSet {
  removeCatalogItemFromBasketSet: boolean;
}

export interface RemoveCatalogItemFromSetVariables {
  basketSetId: number;
  catalogItemId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCatalogItemToSet
// ====================================================

export interface AddCatalogItemToSet_addCatalogItemToSet {
  __typename: "AddCatalogItemToSetResult";
  redirectToSummary: boolean;
}

export interface AddCatalogItemToSet {
  addCatalogItemToSet: AddCatalogItemToSet_addCatalogItemToSet;
}

export interface AddCatalogItemToSetVariables {
  basketSetId: number;
  basketItemId: number;
  catalogItemId: number;
  price: number;
  bailPrice: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetCustomerInfo
// ====================================================

export interface SetCustomerInfo {
  setCustomerInfo: boolean | null;
}

export interface SetCustomerInfoVariables {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  customerId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetDeliveryInfo
// ====================================================

export interface SetDeliveryInfo {
  setDeliveryInfo: boolean | null;
}

export interface SetDeliveryInfoVariables {
  zipCode?: string | null;
  city?: string | null;
  streetNumber?: string | null;
  streetName?: string | null;
  customerId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReservation
// ====================================================

export interface CreateReservation_createReservation {
  __typename: "OrderWithPayment";
  paymentUrl: string;
}

export interface CreateReservation {
  createReservation: CreateReservation_createReservation;
}

export interface CreateReservationVariables {
  order: OrderInput;
  sets?: SetInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveItemFromSet
// ====================================================

export interface RemoveItemFromSet {
  removeItemFromBasketSet: boolean | null;
}

export interface RemoveItemFromSetVariables {
  itemId: number;
  setId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetBaksetSetFilters
// ====================================================

export interface SetBaksetSetFilters {
  setBaksetSetFilters: boolean | null;
}

export interface SetBaksetSetFiltersVariables {
  input: SetBasketSetFilters;
  basketSetId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActivateSetItem
// ====================================================

export interface ActivateSetItem {
  activateSetItem: boolean | null;
}

export interface ActivateSetItemVariables {
  itemId: number;
  setId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSelectedReservationId
// ====================================================

export interface GetSelectedReservationId {
  selectedReservationId: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCanContinueToReservation
// ====================================================

export interface GetCanContinueToReservation {
  canContinueToReservation: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReservationHeaderLabel
// ====================================================

export interface GetReservationHeaderLabel {
  reservationHeaderLabel: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFinishReservation
// ====================================================

export interface GetFinishReservation {
  finishReservation: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBasketModalOpen
// ====================================================

export interface GetBasketModalOpen {
  basketModalOpen: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReservationPriceSum
// ====================================================

export interface ReservationPriceSum_priceSum {
  __typename: "PriceSum";
  bail: number;
  rental: number;
  delivery: number;
}

export interface ReservationPriceSum {
  priceSum: ReservationPriceSum_priceSum;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchAllProducts
// ====================================================

export interface SearchAllProducts {
  searchAllProducts: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCustomerInfo
// ====================================================

export interface GetCustomerInfo_getCustomerInfo {
  __typename: "ReservationCustomerInfo";
  id: number | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  city: string | null;
  zipCode: string | null;
  streetName: string | null;
  streetNumber: string | null;
}

export interface GetCustomerInfo {
  getCustomerInfo: GetCustomerInfo_getCustomerInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReservationSubmitted
// ====================================================

export interface GetReservationSubmitted {
  reservationSubmitted: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetClientFormValid
// ====================================================

export interface GetClientFormValid {
  clientFormValid: boolean;
  allBasketFiltersValid: boolean;
  deliveryFormValid: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAddToBasketModal
// ====================================================

export interface GetAddToBasketModal {
  addedToBasketModal: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetBasketModalOpen
// ====================================================

export interface SetBasketModalOpen {
  setBasketModalOpen: boolean | null;
}

export interface SetBasketModalOpenVariables {
  open: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSelectedPickupTime
// ====================================================

export interface GetSelectedPickupTime {
  pickupTimeId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPickupTimes
// ====================================================

export interface GetPickupTimes_getPickupTimes {
  __typename: "PickupTime";
  id: number;
  from: number;
  to: number;
}

export interface GetPickupTimes {
  getPickupTimes: GetPickupTimes_getPickupTimes[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetReservationsForPickupTime
// ====================================================

export interface GetReservationsForPickupTime {
  getReservationsForPickupTime: number;
}

export interface GetReservationsForPickupTimeVariables {
  pickupTimeId: number;
  date: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Order
// ====================================================

export interface Order_order {
  __typename: "Order";
  id: number;
  paymentUrl: string | null;
}

export interface Order {
  order: Order_order | null;
}

export interface OrderVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConfigQuery
// ====================================================

export interface ConfigQuery_configQuery {
  __typename: "Config";
  seasonEnabled: boolean;
  twoSeasonsEnabled: boolean;
  addressDeliveryDisabled: boolean;
}

export interface ConfigQuery {
  configQuery: ConfigQuery_configQuery;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrderByPaymentQuery
// ====================================================

export interface OrderByPaymentQuery_orderByPayment_payments {
  __typename: "Payment";
  id: string;
  amount: number;
  type: PaymentType;
}

export interface OrderByPaymentQuery_orderByPayment_sets_catalogItems {
  __typename: "CatalogItem";
  id: number;
  name: string;
  price: number | null;
  producer: string;
  categoryName: string | null;
  variant: string;
}

export interface OrderByPaymentQuery_orderByPayment_sets {
  __typename: "Set";
  id: number;
  catalogItems: OrderByPaymentQuery_orderByPayment_sets_catalogItems[] | null;
}

export interface OrderByPaymentQuery_orderByPayment {
  __typename: "Order";
  id: number;
  price: number | null;
  state: OrderState;
  payments: (OrderByPaymentQuery_orderByPayment_payments | null)[] | null;
  sets: OrderByPaymentQuery_orderByPayment_sets[] | null;
  paymentUrl: string | null;
}

export interface OrderByPaymentQuery {
  orderByPayment: OrderByPaymentQuery_orderByPayment | null;
}

export interface OrderByPaymentQueryVariables {
  gopayId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrderDetailQuery
// ====================================================

export interface OrderDetailQuery_order_payments {
  __typename: "Payment";
  id: string;
  amount: number;
  type: PaymentType;
}

export interface OrderDetailQuery_order_sets_catalogItems {
  __typename: "CatalogItem";
  id: number;
  name: string;
  price: number | null;
  producer: string;
  categoryName: string | null;
  variant: string;
}

export interface OrderDetailQuery_order_sets {
  __typename: "Set";
  id: number;
  catalogItems: OrderDetailQuery_order_sets_catalogItems[] | null;
}

export interface OrderDetailQuery_order {
  __typename: "Order";
  id: number;
  price: number | null;
  payments: (OrderDetailQuery_order_payments | null)[] | null;
  sets: OrderDetailQuery_order_sets[] | null;
}

export interface OrderDetailQuery {
  order: OrderDetailQuery_order | null;
}

export interface OrderDetailQueryVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: dateRange
// ====================================================

export interface dateRange {
  __typename: "Reservation";
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BasketSetType {
  CCS = "CCS",
  SKI = "SKI",
  SKIALP_FREERIDE = "SKIALP_FREERIDE",
  SNOWBOARD = "SNOWBOARD",
}

export enum BasketSubType {
  ONLYACCESORIES = "ONLYACCESORIES",
  SET = "SET",
  WITHOUTACCESORIES = "WITHOUTACCESORIES",
}

export enum CCSPurpose {
  GRIP = "GRIP",
  SKIN = "SKIN",
}

export enum CCSStyle {
  KLASIC = "KLASIC",
  SCATE = "SCATE",
}

export enum CatalogItemType {
  BACKPACK = "BACKPACK",
  BAG = "BAG",
  BAG_WITH_ABS = "BAG_WITH_ABS",
  BOOTS = "BOOTS",
  FINDER = "FINDER",
  HEAD = "HEAD",
  MAIN_ITEM = "MAIN_ITEM",
  POLES = "POLES",
}

export enum ChildSex {
  BOY = "BOY",
  GIRL = "GIRL",
}

export enum Fitness {
  BEGINNER = "BEGINNER",
  RECREATIONAL = "RECREATIONAL",
  SPORT = "SPORT",
}

export enum OrderState {
  ACTIVE = "ACTIVE",
  CHANGE = "CHANGE",
  FINISHED = "FINISHED",
  NEW = "NEW",
  PREPARED = "PREPARED",
  PREVIOUS = "PREVIOUS",
  CANCELED = "CANCELED",
  RESERVATION_CANCELED = "RESERVATION_CANCELED",
  RESERVATION_NEW = "RESERVATION_NEW",
  RESERVATION_PAID = "RESERVATION_PAID",
  RESERVATION_PREPARED = "RESERVATION_PREPARED",
}

export enum PaymentType {
  BAIL = "BAIL",
  DELIVERY = "DELIVERY",
  PRICE = "PRICE",
  RETURN = "RETURN",
  RETURN_PRICE = "RETURN_PRICE",
}

export enum RecurringType {
  ONCE = "ONCE",
  YEARLY = "YEARLY",
}

export enum Sex {
  CHILD = "CHILD",
  MAN = "MAN",
  WOMAN = "WOMAN",
}

export enum SkiArc {
  LONG = "LONG",
  SHORT = "SHORT",
  UNIVERSAL = "UNIVERSAL",
}

export enum SkiSlope {
  FREE = "FREE",
  GROOMED = "GROOMED",
}

export enum Skills {
  BEGINNER = "BEGINNER",
  FREERIDE = "FREERIDE",
  RECREATIONAL = "RECREATIONAL",
  SPORT = "SPORT",
}

export enum SnowboardPosition {
  LEFT_FRONT = "LEFT_FRONT",
  RIGHT_FRONT = "RIGHT_FRONT",
}

export interface AddSetToBasketInput {
  reservationId?: number | null;
  sex: Sex;
  type: BasketSetType;
  subType: BasketSubType;
  items?: AddSetToBasketItemInput[] | null;
}

export interface AddSetToBasketItemInput {
  id: CatalogItemType;
  useUniversal?: boolean | null;
}

export interface AllCatalogItemFilter {
  basketSetType: BasketSetType;
  catalogItemType: CatalogItemType;
  from: any;
  to: any;
  season?: boolean | null;
  search?: string | null;
}

export interface CustomerInput {
  name: string;
  id?: string | null;
  phone: string;
  email?: string | null;
}

export interface DeliveryAddressInput {
  streetName?: string | null;
  streetNumber?: string | null;
  city?: string | null;
  zipCode?: string | null;
}

export interface OrderInput {
  from: any;
  to: any;
  note?: string | null;
  customer?: CustomerInput | null;
  deliveryAddress?: DeliveryAddressInput | null;
  season?: boolean | null;
  morningPickUp?: boolean | null;
  pickupTimeId?: number | null;
}

export interface RecomendedCatalogItemFilter {
  snowboardPosition?: SnowboardPosition | null;
  height?: number | null;
  basketSetType: BasketSetType;
  catalogItemType: CatalogItemType;
  weight?: number | null;
  sex?: Sex | null;
  footSize?: number | null;
  skiSlope?: SkiSlope | null;
  skiArc?: SkiArc | null;
  skills?: Skills | null;
  ccsStyle?: CCSStyle | null;
  ccsPurpose?: CCSPurpose | null;
  from?: any | null;
  to?: any | null;
  season?: boolean | null;
  headSize?: number | null;
  useUniversalCatalogItem?: boolean | null;
}

export interface SetBasketSetFilters {
  basketSetId?: number | null;
  height?: number | null;
  weight?: number | null;
  age?: number | null;
  footSize?: number | null;
  fitness?: Fitness | null;
  skiSlope?: SkiSlope | null;
  skiArc?: SkiArc | null;
  skills?: Skills | null;
  ccsStyle?: CCSStyle | null;
  ccsPurpose?: CCSPurpose | null;
  childSex?: ChildSex | null;
}

export interface SetInput {
  height?: number | null;
  weight?: number | null;
  age?: number | null;
  snowboardPosition?: SnowboardPosition | null;
  fitness?: Fitness | null;
  headSize?: number | null;
  basketSetType: BasketSetType;
  items?: SetItemsInput[] | null;
}

export interface SetItemsInput {
  id: number;
  type: CatalogItemType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
