import colors from 'lib/colors';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React, { useLayoutEffect, useState } from 'react';
import './Slider.css';

interface ISliderProps {
  min: number;
  max: number;
  value?: number;
  units: string;
  onAfterCahnge: (value: number) => void;
}

const Slider: React.FC<ISliderProps> = props => {
  const isTouchDevice = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };

  const [value, setValue] = useState(props.value || props.min);
  const [touchDevice, setTouchDevice] = useState(isTouchDevice());

  useLayoutEffect(() => {
    window.addEventListener('touchstart', onFirstTouch);

    return () => {
      window.removeEventListener('touchstart', onFirstTouch);
    };
  });

  const onFirstTouch = () => !touchDevice && setTouchDevice(true);

  return (
    <RCSlider
      min={props.min}
      max={props.max}
      handle={(handleProps: any) => (
        <SliderTooltip
          handleProps={handleProps}
          units={props.units}
          allowTopTooltip={touchDevice}
        />
      )}
      value={value}
      onChange={setValue}
      onAfterChange={props.onAfterCahnge}
      railStyle={{ backgroundColor: colors.black, height: 1 }}
      trackStyle={{ backgroundColor: colors.primary, height: 2 }}
      handleStyle={[
        {
          backgroundColor: colors.primary,
          border: 'none',
          width: 20,
          height: 20,
          marginTop: '-9px',
        },
      ]}
    />
  );
};
export default Slider;

interface ISliderTooltipsProps {
  handleProps: any;
  units: string;
  allowTopTooltip: boolean;
}

const SliderTooltip = (props: ISliderTooltipsProps) => {
  // @ts-ignore
  const Handle = RCSlider.Handle;
  const { value, dragging, index, ...restProps } = props.handleProps;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} ${props.units}`}
      visible
      placement="bottom"
      overlayClassName="bottom"
      key={index}
    >
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        placement="top"
        key={index}
        visible={props.allowTopTooltip && dragging}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    </Tooltip>
  );
};
