import styled from '@emotion/styled';
import { Link, RouteComponentProps } from '@reach/router';
import { Box, Flex, Text } from 'components';
import PageWrapper from 'components/PageWrapper';
import colors from 'lib/colors';
import React from 'react';

const Maintance: React.FC<RouteComponentProps> = (props) => {
  const errorMessage = props.location?.state?.errorMessage;
    return(
      <PageWrapper hideFooter simpleHeader>
        <Flex mt={6} justifyContent="center" flexDirection="column">
          <Flex mt={4} alignItems="center" flexDirection="column">
            <Text fontSize={[18, 36]} textAlign="center">{errorMessage}</Text>
            <Box mt={4}>
              <Link to="/" css={{ textDecoration: 'none' }}>
                <LinkButton>
                  <Text fontSize={[12, 16, 18]}>ZKUSTE ZNOVU</Text>
                </LinkButton>
              </Link>
            </Box>
          </Flex>
        </Flex>
      </PageWrapper>
    ) 
}

export default Maintance;

const LinkButton = styled.div({
  color: colors.primary,
  fontWeight: 'bolder',
  textDecoration: 'none',
  padding: '12px 48px',
  border: `1px solid ${colors.primary}`,
});
