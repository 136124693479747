import { init } from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

// @ts-ignore
const dsn = window.SENTRY_DSN;
// @ts-ignore
const environment = window.ENVIRONMENT;

init({
  enabled: Boolean(dsn),
  dsn,
  environment,
  integrations: [new Integrations.Dedupe(), new Integrations.ExtraErrorData()],
});
