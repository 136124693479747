import colors from 'lib/colors';
import { transparentize } from 'polished';
import React from 'react';
import Box from './Box';
import Flex from './Flex';
import { SpinnigLoader } from './SpinningLoader';

interface ILoadingProps {
  transparent: boolean;
}

const Loading: React.FC<ILoadingProps> = ({ transparent }) => {
  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      backgroundColor={transparent ? transparentize(0.9, colors.greyDark) : 'initial'}
    >
      <Flex height="100%" justifyContent={'center'} alignItems={'flex-start'} mt={[30, 40]}>
        <SpinnigLoader />
      </Flex>
    </Box>
  );
};

export default Loading;
