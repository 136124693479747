import styled from '@emotion/styled';
import colors from 'lib/colors';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as ArrowSVG } from '../assets/arrow.svg';
import Flex from './Flex';
import Text from './Text';

interface Props {
  items: { id: string; name: string }[];
  onSelect: (id: string) => void;
  onClick?: () => void;
  disabled?: boolean;
  label?: string;
  selected: string;
  wizardComponent?: boolean;
  error?: boolean;
}

const SelectInput = ({
  items,
  onSelect,
  disabled,
  label,
  selected,
  onClick,
  wizardComponent,
  error
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleList = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (items.length !== 1) {
      setIsOpen(prevState => !prevState);
      if (onClick) onClick();
    }
  };

  const closeList = () => {
    setIsOpen(false);
  };

  const handleSelect = (id: string) => {
    setIsOpen(false);
    onSelect(id);
  };

  const selectedItem = items.find(item => item.id === selected);
  return (
    <Container wizardComponent={wizardComponent}>
      <Flex pl={wizardComponent ? 0 : 3} mb={2}>
        <Text fontSize="13px" lineHeight={wizardComponent ? '20px' : 'auto'}>
          {label}
        </Text>
      </Flex>
      <OutsideClickHandler onOutsideClick={closeList}>
        <Flex position="relative" width="100%">
          <InputContainer
            role="button"
            className="dd-header"
            onClick={toggleList}
            disabled={!!disabled}
            wizardComponent={wizardComponent}
            css={error ? {
              border: `solid 1px ${colors.red}`
            } : undefined}
          >
            <TextBox>
              <Text>{selectedItem ? selectedItem.name : 'Vyberte'}</Text>
            </TextBox>
            {items.length !== 1 && (
              <ArrowContainer>
                {isOpen ? <ArrowUp /> : <ArrowDown disabled={!!disabled} />}
              </ArrowContainer>
            )}
          </InputContainer>
          {isOpen && (
            <ListContainer>
              {items.map((item, index) => (
                <SelectItem
                  key={index}
                  onClick={handleSelect}
                  item={item}
                  isSelected={selected === item.id}
                />
              ))}
            </ListContainer>
          )}
        </Flex>
      </OutsideClickHandler>
    </Container>
  );
};

interface ItemProps {
  onClick: (id: string) => void;
  item: { name: string; id: string };
  isSelected: boolean;
}

const SelectItem = ({ onClick, item, isSelected }: ItemProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onClick(item.id);
  };
  return (
    <ItemContainer isSelected={isSelected}>
      <Button onClick={handleClick}>
        <span>{item.name}</span>
      </Button>
    </ItemContainer>
  );
};

export default SelectInput;

const ItemContainer = styled.div<{ isSelected: boolean }>`
  padding: 8px 16px;
  background: ${props => (props.isSelected ? colors.grey : colors.white)};
  :hover {
    background-color: ${colors.grey};
  }
`;

const Button = styled.button`
  border: none;
  padding: 0;
  background: transparent;
  font-size: 13px;
  width: 100%;
  outline: none;
  text-align: left;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;

const TextBox = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Container = styled.div<{ wizardComponent?: boolean }>`
  position: relative;
  width: 100%;
  ${props => (props.wizardComponent ? 'margin: 8px;' : 'margin-top: 16px;')}
`;

const InputContainer = styled(Flex)<{ disabled: boolean; wizardComponent?: boolean }>`
  align-items: center;
  border: ${`1px solid ${colors.border}`};
  color: ${props => (props.disabled ? colors.border : colors.black)};
  background-color: ${props =>
    props.wizardComponent ? colors.white : props.disabled ? colors.white : colors.grey};
  padding: 9px;
  padding-left: 16px;
  padding-right: 2px;
  font-size: 14px;
  white-space: nowrap;
  position: relative;
  width: 100%;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const ListContainer = styled.ul`
  position: absolute;
  top: 33px;
  left: 0;
  right: 0;
  border: ${`1px solid ${colors.border}`};
  z-index: 10;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 235px;
  overflow-y: auto;
  background: ${colors.white};
`;

const ArrowDown = styled(ArrowSVG)<{ disabled: boolean }>`
  height: 7.5px;
  color: ${props => (props.disabled ? colors.border : colors.black)};
`;

const ArrowUp = styled(ArrowSVG)`
  transform: rotate(180deg);
  height: 7.5px;
  color: ${colors.black};
`;
