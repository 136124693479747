import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import colors from 'lib/colors';
import React from 'react';
import { mq } from 'theme';
import errorImg from '../assets/error.png';
import Box from './Box';
import Flex from './Flex';
import PageWrapper from './PageWrapper';
import Text from './Text';

interface FallbackComponentProps {
  error: Error;
  componentStack: string;
  eventId?: string;
}

interface Props {
  children?: React.ReactNode;
  FallbackComponent: React.ComponentType<FallbackComponentProps>;
}

interface State {
  error?: Error;
  info?: React.ErrorInfo;
  eventId?: string;
}

const DefaultFallbackComponent = ({ eventId }: FallbackComponentProps) => {
  const handleOnClick = () => {
    window.location.reload();
  };

  return (
    <PageWrapper hideFooter>
      <Flex mt={6} justifyContent="center" flexDirection="column">
        <Image src={errorImg} alt="error" />
        <Flex mt={4} alignItems="center" flexDirection="column">
          <Text fontSize={[18, 36, 50]}>Něco se pokazilo</Text>
          <Box mt={4}>
            <Button onClick={handleOnClick}>
              <Text fontSize={[12, 16, 18]}>ZKUSIT ZNOVU</Text>
            </Button>
          </Box>
        </Flex>
      </Flex>
    </PageWrapper>
  );
};

/**
 * Covers components, and catchs fails which sends to sentry
 */
class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    FallbackComponent: DefaultFallbackComponent,
  };

  state: State = {};

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(info);
      scope.setTag('logger', 'ErrorBoundary');
      const eventId = Sentry.captureException(error);
      this.setState({ error, info, eventId });
    });
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, info, eventId } = this.state;
    if (error != null) {
      console.error({ eventId });
      return (
        <FallbackComponent
          error={error}
          componentStack={info != null ? info.componentStack : ''}
          eventId={eventId}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;

const Button = styled.div({
  color: colors.primary,
  fontWeight: 'bolder',
  textDecoration: 'none',
  padding: '12px 48px',
  border: `1px solid ${colors.primary}`,
});

const Image = styled.img({
  width: 'auto',
  height: 64,
  objectFit: 'contain',
  [mq[0]]: {
    height: 92,
  },
  [mq[1]]: {
    height: 128,
  },
});
