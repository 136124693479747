import { OperationVariables, QueryResult } from '@apollo/react-common';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import React from 'react';

type ChildrenArgs<TData> = Omit<QueryResult<TData>, 'loading' | 'data' | 'error'> & { data: TData };

interface Props<TData, TVariables> {
  query: DocumentNode;
  options?: QueryHookOptions<TData, TVariables>;
  customLoading?: boolean;
  children: (result: ChildrenArgs<TData> & { loading: boolean }) => JSX.Element | null;
}

export default function QueryComponent<TData = any, TVariables = OperationVariables>({
  query,
  children,
  options,
  customLoading = false,
}: Props<TData, TVariables>) {
  const { loading, error, data, ...others } = useQuery<TData, TVariables>(query, options);
  // TODO: add some nice loading and error modals
  if (!customLoading) {
    if (loading) {
      return <>Loading</>;
    }
  }
  if (error || data === undefined) {
    // const errName = error?.graphQLErrors[0].name
    // const isErrorKnown = isServerErrorKnown(errName || '');
    // if(!isErrorKnown){
    return <>Error</>;
    // }
  }

  return children({ data, loading, ...others });
}
