import { useMutation } from '@apollo/react-hooks';
import { css } from '@emotion/core';
import { navigate } from '@reach/router';
import { Box, Flex, Loading, Text } from 'components';
import GruppedCatalogItem from 'components/GruppedCatalogItems/GruppedCatalogItem';
import { addCatalogItemToSet } from 'graphql/queries';
import { getServerErrorTransaltion } from 'graphql/ServerErrors';
import {
  AddCatalogItemToSet,
  AddCatalogItemToSetVariables,
  BasketSetType,
  GetActiveBasketSet_basketSet_items,
} from 'graphql/types';
import colors from 'lib/colors';
import { ServerErrorContext } from 'lib/enums';
import { GruppedCatalogItem as GruppedCatalogItemType } from 'lib/types';
import React, { useState } from 'react';

interface ItemsIProps {
  items: GruppedCatalogItemType[];
  basketSetId: number;
  activeBasketItem: GetActiveBasketSet_basketSet_items;
  setType: BasketSetType;
  loading: boolean;
  errorName: string | undefined;
  disabled: boolean;
  selectedItemId: number | undefined | null;
}

const GruppedCatalogItems: React.FC<ItemsIProps> = props => {
  const [activeId, setActiveId] = useState<number | null>(
    props.activeBasketItem.catalogItemId ?? props.items[0]?.id,
  );

  const [addItemToSet] = useMutation<AddCatalogItemToSet, AddCatalogItemToSetVariables>(
    addCatalogItemToSet,
    {
      onCompleted: data => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (data.addCatalogItemToSet.redirectToSummary) navigate('/reservation-summary');
      },
    },
  );

  const handleSelectCatalogItem = (catalogItemId: number, price: number, bailPrice: number) => {
    addItemToSet({
      variables: {
        catalogItemId,
        price,
        bailPrice,
        basketSetId: props.basketSetId,
        basketItemId: props.activeBasketItem.id,
      },
    });
  };

  const errTranslation = getServerErrorTransaltion(
    props.errorName || '',
    ServerErrorContext.GET_RECOMENDED_ITEMS,
  );

  const catalogItemDisabled = props.disabled || props.loading;
  return (
    <Flex
      flexWrap={'wrap'}
      mt={[4]}
      justifyContent={['center', 'flex-start']}
      minHeight={[541]}
      position="relative"
    >
      <>
        {props.errorName ? (
          <Box
            css={css({
              width: '100%',
              textAlign: 'center',
              color: colors.red,
              marginTop: 20,
              marginBottom: 40,
              maxHeight: props.errorName ? 50 : 0,
              transition: `max-height 0.15s ${props.errorName ? 'ease-in' : 'ease-out'}`,
              fontWeight: 'bolder',
              overflow: 'hidden',
            })}
          >
            <Text>{errTranslation}</Text>
          </Box>
        ) : (
          <Box />
        )}
        {props.items.map((item, idx) => (
          <GruppedCatalogItem
            isFirst={idx === 0}
            key={item.id}
            {...item}
            activeId={activeId}
            setActive={setActiveId}
            onSelect={handleSelectCatalogItem}
            basketItemType={props.activeBasketItem.type}
            setType={props.setType}
            isDisabled={catalogItemDisabled}
            selectedItemId={props.selectedItemId}
          />
        ))}
        {props.loading && <Loading transparent={props.items.length > 0} />}
      </>
    </Flex>
  );
};

export default GruppedCatalogItems;
