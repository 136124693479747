import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { Link, navigate } from '@reach/router';
import { Button, Flex } from 'components';
import QueryComponent from 'components/QueryComponent';
import {
  createReservation,
  getBasket,
  getCanContinueToReservation,
  getClientFormValid,
  getCustomerInfo,
  getFinishReservation,
  getReservationDateRange,
  getSelectedPickupTime,
  removeCatalogItemFromBasketSet,
  reservationPriceSum,
} from 'graphql/queries';
import { getServerErrorTransaltion } from 'graphql/ServerErrors';
import {
  CreateReservation,
  CreateReservationVariables,
  GetBasket,
  GetCanContinueToReservation,
  GetClientFormValid,
  GetCustomerInfo,
  GetFinishReservation,
  GetReservationDateRange,
  GetSelectedPickupTime,
  RemoveCatalogItemFromSet,
  RemoveCatalogItemFromSetVariables,
  ReservationPriceSum,
} from 'graphql/types';
import colors from 'lib/colors';
import { ServerErrorContext } from 'lib/enums';
import { stylePrice } from 'lib/utils';
import { transparentize } from 'polished';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Box from './Box';
import Text from './Text';

interface IProps {
  home?: boolean;
  reservation?: boolean;
  summary?: boolean;
}

const Footer: React.FC<IProps> = ({ home, reservation, summary }) => {
  const [makeReservation] = useMutation<CreateReservation, CreateReservationVariables>(
    createReservation,
  );
  const [removeItem] = useMutation<RemoveCatalogItemFromSet, RemoveCatalogItemFromSetVariables>(
    removeCatalogItemFromBasketSet,
  );
  const basketData = useQuery<GetBasket>(getBasket);
  const client = useApolloClient();

  const submitCreateReservation = async (data: GetClientFormValid, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    client.writeData({ data: { reservationSubmitted: true } });
    if(
      data.clientFormValid &&
      data.allBasketFiltersValid &&
      data.deliveryFormValid
    ) {
      handleCreateReservation(e);
    }
  }
  const handleCreateReservation = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const dateRange: GetReservationDateRange | null = client.readQuery({
      query: getReservationDateRange,
    });
    const basket: GetBasket | null = client.readQuery({ query: getBasket });
    const { data } = await client.query<GetCustomerInfo>({
      query: getCustomerInfo,
      fetchPolicy: 'no-cache',
    });
    const pickUpTime: GetSelectedPickupTime | null = client.readQuery({
      query: getSelectedPickupTime,
    });
    if (dateRange && basket && data.getCustomerInfo && pickUpTime) {
      makeReservation({
        variables: {
          order: {
            from: dateRange.reservation.from,
            to: dateRange.reservation.to,
            season: dateRange.reservation.season,
            morningPickUp: !dateRange.reservation.afternoonPickup,
            customer: {
              name: `${data.getCustomerInfo.firstName} ${data.getCustomerInfo.lastName}`,
              phone: data.getCustomerInfo.phoneNumber || '',
              email: data.getCustomerInfo.email,
            },
            deliveryAddress: data.getCustomerInfo.streetName
              ? {
                  streetName: data.getCustomerInfo.streetName,
                  streetNumber: data.getCustomerInfo.streetNumber,
                  city: data.getCustomerInfo.city,
                  zipCode: data.getCustomerInfo.zipCode,
                }
              : null,
            pickupTimeId: parseInt(pickUpTime.pickupTimeId, 10),
          },
          sets: basket.basket.sets.map(set => ({
            height: set.filters.height,
            weight: set.filters.weight,
            age: set.filters.age,
            fitness: set.filters.fitness,
            basketSetType: set.type,
            snowboardPosition: set.filters.snowboardPosition,
            headSize: set.filters.headSize,
            items: set.items.map(item => ({ id: item.catalogItemId || -1, type: item.type })),
          })),
        },
      })
        .then(({ data }) => {
          navigate(data?.createReservation.paymentUrl ? `/bank-redirecting` : '/order-error', { state: { paymentUrl: data?.createReservation.paymentUrl}});
        })
        .catch(error => {
          const graphQlError = error?.graphQLErrors[0];
          if (
            graphQlError.name === 'ItemNotAvailable' ||
            graphQlError.name === 'ItemIsNotCompatibleWithSet' ||
            graphQlError.name === 'canNotAddSetItemToExistingSet'
          ) {
            graphQlError.info.itemIds.forEach((catalogItemId: number) => {
              const basketSetId = basketData.data?.basket.sets.find(set =>
                set.items.find(item => item.catalogItemId === catalogItemId && !item.useUniversal),
              )?.id;
              if (basketSetId) {
                removeItem({ variables: { catalogItemId, basketSetId } });
              }
            });
            alert(
              getServerErrorTransaltion(
                error?.graphQLErrors[0].name,
                ServerErrorContext.CREATE_RESERVATION,
              ),
            );
            navigate('/reservation', { replace: true });
          } else {
            // @ts-ignore
            if (window.ENVIRONMENT !== 'production') {
              alert(error);
            }
          }
        });
    }
  };

  return (
    <Flex justifyContent="center" flexWrap="nowrap">
      <Flex width={['0px', '40%']} justifyContent="center" alignItems="center">
        {(reservation || summary) && (
          <Box display={['none', 'none', 'none', 'block']}>
            <Flex justifyContent="center" flexDirection={'column'}>
              <QueryComponent<ReservationPriceSum> query={reservationPriceSum}>
                {({ data }) => (
                  <Flex flexDirection={['column', 'row']}>
                    <Flex flexDirection="column">
                      <Text
                        css={css({ fontSize: 13, color: colors.black, textTransform: 'uppercase' })}
                      >
                        {reservation ? 'Průběžná cena' : 'Celková cena'}
                      </Text>
                      <Text
                        css={css({
                          fontSize: 20,
                          fontWeight: 'bolder',
                          color: colors.primary,
                        })}
                      >
                        {stylePrice(data.priceSum.rental + data.priceSum.delivery)}
                      </Text>
                    </Flex>
                    <Flex ml={[0, 4]} mt={[3, 0]} flexDirection="column">
                      <Text
                        css={css({ fontSize: 13, color: colors.black, textTransform: 'uppercase' })}
                      >
                        {reservation ? 'Průběžná kauce' : 'Celková kauce'}
                      </Text>
                      <Text
                        css={css({
                          fontSize: 20,
                          fontWeight: 'bolder',
                          color: colors.primary,
                        })}
                      >
                        {stylePrice(data.priceSum.bail)}
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </QueryComponent>
            </Flex>
          </Box>
        )}
      </Flex>
      <Flex
        flexDirection={['row']}
        width={['100%', '60%']}
        paddingY={[3, 3, 3, 3]}
        justifyContent={['stretch', 'stretch', 'space-between', 'space-evenly']}
        alignItems="center"
      >
        {home && (
          <Box width={['auto', '100%']} display={['block']} data-tip data-for="continueHome">
            <Flex justifyContent={'center'}>
              <QueryComponent<GetCanContinueToReservation> query={getCanContinueToReservation}>
                {({ data }) => (
                  <>
                    <NextButtonLink to="/reservation" disabled={!data.canContinueToReservation}>
                      <Text>pokračovat</Text>
                    </NextButtonLink>
                    {!data.canContinueToReservation && (
                      <ReactTooltip id="continueHome" type="error" effect="solid">
                        <span>
                          Musíte zvolit datum zápůjčky <br /> a tlačítkem „vybrat“ přidat <br />{' '}
                          zvolené vybavení do košíku.
                        </span>
                      </ReactTooltip>
                    )}
                  </>
                )}
              </QueryComponent>
            </Flex>
          </Box>
        )}
        {reservation && (
          <Box
            display={['none', 'none', 'none', 'flex']}
            width="100%"
            data-tip
            data-for="continueReservation"
          >
            <Flex justifyContent="center" width="100%">
              <BackButtonLink to={'/'}>Zpět</BackButtonLink>
              <QueryComponent<GetFinishReservation> query={getFinishReservation}>
                {({ data }) => (
                  <>
                    <NextButtonLink to="/reservation-summary" disabled={!data.finishReservation}>
                      pokračovat
                    </NextButtonLink>
                    {!data.finishReservation && (
                      <ReactTooltip id="continueReservation" type="error" effect="solid">
                        <span>Musíte si vybrat všechny položky do všech setů</span>
                      </ReactTooltip>
                    )}
                  </>
                )}
              </QueryComponent>
            </Flex>
          </Box>
        )}
        {summary && (
          <Flex
            paddingY={[2, 2, 2, 0]}
            width="100%"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box display={['none', 'flex']}>
              <BackButtonLink to={'/reservation'}>Zpět</BackButtonLink>
            </Box>
            <Box display={['flex', 'none']}>
              <BackButtonLink smallButton to={'/reservation'}>
                Zpět
              </BackButtonLink>
            </Box>
            <QueryComponent<GetClientFormValid> query={getClientFormValid}>
              {({ data }) => (
                <>
                  <Box data-tip data-for="payButton">
                    <Button
                      display={['none', 'inline-block']}
                      css={css({
                        backgroundColor: colors.white,
                        fontSize: 18,
                        fontWeight: 'bold',
                        padding: '16px 48px',
                        textTransform: 'uppercase',
                        color: colors.green,
                        border: `1px solid ${colors.green}`,
                        ':disabled': {
                          borderColor: transparentize(0.6, colors.green),
                          color: transparentize(0.6, colors.green),
                        },
                      })}
                      onClick={(e) => {
                        submitCreateReservation(data, e);
                      }}
                      label={'Dokončit a zaplatit'}
                    />
                    <Button
                      display={['inline-block', 'none']}
                      css={css({
                        backgroundColor: colors.white,
                        fontSize: 14,
                        padding: '8px 24px',
                        textTransform: 'uppercase',
                        color: colors.green,
                        border: `1px solid ${colors.green}`,
                        ':disabled': {
                          borderColor: transparentize(0.6, colors.green),
                          color: transparentize(0.6, colors.green),
                        },
                      })}
                      onClick={(e) => {
                        submitCreateReservation(data, e);
                      }}
                      label={'Dokončit a zaplatit'}
                    />
                  </Box>
                  {(!data.clientFormValid || !data.allBasketFiltersValid) && (
                    <ReactTooltip id="payButton" type="error" effect="solid">
                      <span>
                      Povinná pole musí být vyplněna, aby bylo možné pokračovat v objednávce.
                      </span>
                    </ReactTooltip>
                  )}
                </>
              )}
            </QueryComponent>
          </Flex>
        )}
        <Flex alignItems={'center'} display={[summary ? 'none' : 'flex', 'flex']} ml={20}>
          <a href={'https://eshop.czski.cz/'}>
            <Button
              label={'Zpět na CZ SKI'}
              width={'max-content'}
              display={[summary ? 'none' : 'box', 'inline-block']}
            />
          </a>
        </Flex>
      </Flex>
      {/* <Box width={['0px', '20%']}></Box> */}
    </Flex>
  );
};

export default Footer;

const NextButtonLink = styled(Link)<{ disabled: boolean }>(props => ({
  backgroundColor: props.disabled ? transparentize(0.4, colors.green) : colors.green,
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 48px',
  textTransform: 'uppercase',
  color: colors.white,
  pointerEvents: props.disabled ? 'none' : 'auto',
  textDecoration: 'none',
}));
// TODO: this should work, but it will throw error inside emotion lib in prod enviroment
// const NextButton = styled(Button)({
//   backgroundColor: colors.white,
//   fontSize: 18,
//   fontWeight: 'bold',
//   padding: '16px 48px',
//   textTransform: 'uppercase',
//   color: colors.green,
//   border: `1px solid ${colors.green}`,
//   ':disabled': {
//     borderColor: transparentize(0.6, colors.green),
//     color: transparentize(0.6, colors.green),
//   },
// });

const BackButtonLink = styled(Link)<{ smallButton?: boolean }>(props => ({
  backgroundColor: 'transparent',
  fontSize: props.smallButton ? 14 : 18,
  fontWeight: 'bold',
  color: colors.backButtonGray,
  marginRight: props.smallButton ? 28 : 56,
  marginLeft: '20%',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
}));
