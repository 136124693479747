import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  color,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  textAlign,
  TextAlignProps,
  TextColorProps,
} from 'styled-system';

export type TextProps =
  | FontWeightProps
  | TextAlignProps
  | FontSizeProps
  | LineHeightProps
  | TextColorProps;

/**
 * Style text component
 */

const Text = styled('span', { shouldForwardProp })<TextProps>`
  ${color}
  ${fontWeight}
  ${fontSize}
  ${textAlign}
  ${lineHeight}
`;

Text.displayName = 'Text';

export default Text;
