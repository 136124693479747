import { createMuiTheme } from '@material-ui/core';
import colors from './lib/colors';

const breakpoints = ['40em', '52em', '64em', '80em'];

export const theme = {
  colors,
  breakpoints,
};

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary
    },
  },
});

type Theme = typeof theme;
export interface AppTheme extends Theme {}

export const mq = breakpoints.map(bp => `@media (min-width: ${bp})`);
